﻿/* ==========================================================================
    VARIABLES
   ========================================================================== */

@import '../framework/breakpoint';

$red: #EC2129;
$gray: #65656a;
$medium-gray: #B3B2B2;
$light-blue: #f3f3f3;
$light-gray: #d6d7d8;
$tan: #c7b299;
$white: #ffffff;
$black: #434343;
$dark-gray: #454545;
$background: #f2f2f2;
$outline: #434343;
$blue: #EC2129;

body.page-im {
    overflow-y: auto;
    overflow-x: hidden;
    width: 99.5%;
}

#im-container {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    background-color: #ffffff;
    display: flex;
}

#im-box {
    width: 75%;
    height: 100%;
    position: static;
    z-index: 60;
    background-color: #fff;
    right: 0;
    overflow-y: auto;
    max-height: 900px;
    overflow-x: hidden;
}


#im-view {
    width: 100%;
    height: 100%;
    background-color: $white;
    box-sizing: border-box;
}

.im-view-top {
    width: 100%;
    padding-top: 0px;
    background-color: #fff;
}

.ctr {
    width: auto;
    text-align: center;
    margin-bottom: 0.50%;
    margin-top: .50%;
    padding-top: 20px;
}

.im-overlay {
    position: absolute;
    left: 0px;
    z-index: 500;
    position: relative;
}

#cropPhoto {
    width: 100%;
}

#map-box {
    width: 100%;
    display: none;
    z-index: 10;
}

#map-view {
    width: 626px;
    float: right;
}

.map-view-top {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: .5%;
    margin-top: .5%;
    background-color: #ffffff;
}

.ctr-map {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
}

.btnim.active {
    color: $white;
    cursor: pointer;
    text-decoration: none !important;
}

#btnChangeRoom {
}

#btnRotate {
}

#viz {
    height: auto !important;
}

.ImageMapper {
    height: auto !important;
    position: static !important;

    > canvas:nth-child(2) {
        position: static !important;
    }
}

.btnim {
    text-align: center;
    -moz-border-radius: 5px;
    box-sizing: border-box;
    display: inline-block;
    width: auto;
    font-size: 14px;
    color: $white;
    cursor: pointer;
    text-transform: uppercase;
    padding: 10px 30px;
    background-color: $outline;
    border: solid 1px $outline;
    margin: 1px;
}

#zoom-controls {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}

#panning-area {
    margin-top: 10px;
    display: flex;
    align-items: center;
    visibility: hidden;
    -webkit-user-select: none;
    display: none;
}

.btnim.btnpan {
    margin: 0 2px;
    padding: 6px 10px;
}

.btnim.mod {
    width: 75%;
    margin: 2%;
}

.cancel.viz-button.span2.btnim.mod.active.hvr-hollow {
    display: none;
}


.mapping-box {
    width: 588px;
    border: solid 2px #fff;
    background-color: #fff;
    margin: 0 auto;
}

.mapping-nav {
    width: 100%;
    height: auto;
    background-color: $white;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    margin-top: 2px;
    justify-content: space-around;
}

.mapping-nav-left {
    padding-left: 3px;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mapping-nav-right {
    margin-top: 8px;
    padding: 4px 0;
    display: flex;
    flex-flow: row nowrap;
    flex: 0 0 auto;
}

#im-tools {
    width: 35%;
    background-color: #ffffff;
    position: relative;
    flex: 0 0 auto;
    z-index: 551;

    > div {
        padding-bottom: 10px;
        background-color: #ffffff;
    }
}

#im-crop {
    width: 100%;
    height: 100%;
    background-color: $background;
    left: 0;
    right: 0;
    top: 0;
    z-index: 22230;
    /*box-shadow: 5px 0px 10px -5px #585858;*/
}



/*VIDEO HELP DIV - WILL SHOW LATER */

.crop-fullscreen {
    width: 100%;
    height: auto;
    text-align: center;
    color: #FFF;
    text-decoration: underline;
    cursor: pointer;
    text-decoration: none;
    background-repeat: no-repeat;
    margin: 0 auto;
}

.crop-fullscreen img {
    width: 70%;
    height: 100%;
}

.crop-caption {
    background-color: $gray;
    font-size: 1.5vh;
    padding: 3% 2% 2%;
    box-sizing: border-box;
    margin-top: -2%;
}


.crop-box {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
}

.crop-box h2 {
    font-size: 28px;
    text-align: center;
    font-weight: 700;
    color: $black;
    margin-bottom: 20px;
    padding: 0;
    margin-top: 10px;
    font-style: normal;
    text-transform: uppercase;
}

.crop-box p {
    text-align: center;
    font-size: 14px;
    color: $black;
    margin: 30px 0;
    text-align: left;
}


.perspective-fullscreen {
    background-image: url(/img/vid/perspective_help.svg);
    /* border: 3px solid #222; */
    text-align: center;
    color: #FFF;
    text-decoration: underline;
    cursor: pointer;
    text-decoration: none;
    background-repeat: no-repeat;
    margin: 0 auto;
    width: 75%;
    height: 16vw;
    position: relative;
    /* border: 1px solid #999999; */
    width: 100%;
    height: 150px;
    background-size: contain;
    background-position: center;
}


.perspective-caption {
    height: 23px;
    width: 178px;
    background-image: url(/img/black.png);
    margin: 98px auto 0 auto;
    border-radius: 0 0 5px 5px;
}


.perspective-box {
    width: 200px;
    height: 425px;
    margin: 95px auto 0 auto;
}

.perspective-box h2 {
    color: $gray;
    font-size: 22px;
    font-weight: bold;
    line-height: 26pt;
    text-align: center;
    text-transform: uppercase;
}

.perspective-box p {
    text-align: left;
    font-size: 13px;
    line-height: 17px;
    padding-left: 5px;
    font-weight: 500;
    color: #58585a;
}

#im-floor {
    width: 100%;
    height: 100%;
    background-color: $background;
    left: 0;
    right: 0;
    top: 0;
    z-index: 220;
    display: none;
    /*box-shadow: 5px 0px 10px -5px #585858;*/
}



.floor-area ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.floor-area ul li.active {
    color: #483698;
}


.divider {
    width: 93%;
    /* height: 10px; */
    border-top: solid 1px $background;
    margin: 6px auto;
    flex: 0 0 auto;
}

.step {
    text-align: center;
    color: $black;
    font-size: 17px;
    flex: 0 0 auto;
}

.area {
    text-align: center;
    font-size: 22px;
    color: $black;
    margin-top: 4px;
    font-weight: 700;
    text-transform: Uppercase;
    flex: 0 0 auto;
}


.choose-tool {
    width: 100%;
    margin: 20px 0;
    padding: 0 10px;
}

.choose-tool img {
    width: 6vmin;
    height: 6vmin;
    margin-bottom: 5px;
}


#painttool ul li, #erasetool ul li {
    width: 100% !important;
    border: solid 1px $black;
}

#painttool ul li img, #erasetool ul li img {
    width: 100% !important;
    height: 100%;
}

.choose-tool ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
}

.choose-tool ul li.active {
    color: $white;
    background-color: $red;
    border: solid 1px $red;
}

.choose-tool ul li.middle {
    width: 48%;
    /* border-left: solid 1px #DBDBDB; */
}

.choose-tool ul li.rights {
}

.choose-tool ul li {
    width: 48%;
    color: $white;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 10px;
    border: solid 1px $dark-gray;
    margin: 1px;
    background-color: $dark-gray;
}

.choose-tool ul li i {
    font-size: 4vmin;
}

ul.paint-tool, ul.erase-tool {
    display: flex;
    flex-flow: column nowrap;
}

li.left {
    /*border-right: solid 1px #DBDBDB;
    border-bottom: solid 1px #DBDBDB;*/
    /* margin-bottom: 10px; */
}

li.rights {
    /*border-left: solid 1px #DBDBDB;
    border-top: solid 1px #DBDBDB;*/
    /* margin-bottom: 10px; */
}

/*VIDEO HELP DIV - WILL SHOW LATER */

.tool-fullscreen {
    text-align: center;
    color: #FFF;
    text-decoration: underline;
    cursor: pointer;
    text-decoration: none;
    background-repeat: no-repeat;
    margin: 0 auto;
    position: relative;
}

.tool-caption {
    /*background-color: rgba(0, 0, 0, 0.75);*/
    font-size: 1.5vh;
    padding: 3% 2% 2%;
    margin-top: -2%;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    bottom: 0px;
}

.tool-caption {
    display: none;
}

.tool-text {
    font-size: 14px;
    text-align: left;
    color: $black;
    width: 81%;
    box-sizing: border-box;
    padding: 0;
    margin: 10px auto 0;
}

.tool-text.small {
}

.tool-text ul {
}

div.floor-tools div.tool-text ol, div.floor-tools div.tool-text p {
    display: none;
}

div.floor-tools div.tool-text ol {
    padding: 0 0 0 14px;
}

div.floor-tools.outline div.tool-text .useDraw {
    display: block;
}

div.floor-tools.paint div.tool-text .usePaint {
    display: block;
}

div.floor-tools.erase div.tool-text .useErase {
    display: block;
}

div.floor-tools.fill div.tool-text .useWand {
    display: block;
}


div.floor-tools.outline .tool-fullscreen {
    background: url(/img/vid/outline_help.svg) center no-repeat;
    width: 100%;
    height: 150px;
    background-size: contain;
}

div.floor-tools.paint .tool-fullscreen {
    background: url(/img/vid/paint_help.svg) center no-repeat;
    width: 100%;
    height: 150px;
    background-size: contain;
}

div.floor-tools.erase .tool-fullscreen {
    background: url(/img/vid/erase_help.svg) center no-repeat;
    width: 100%;
    height: 150px;
    background-size: contain;
}

div.floor-tools.fill .tool-fullscreen {
    background: url(/img/vid/fill_help.svg) center no-repeat;
    width: 100%;
    height: 150px;
    background-size: contain;
}


.tool-text li {
    margin-bottom: 5px;
    color: $black;
    font-size: 12px;
}

.tool-sc {
    width: 100%;
    flex: 0 0 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

#im-angle {
    width: 100%;
    height: 100%;
    background-color: $background;
    left: 0;
    right: 0;
    top: 0;
    z-index: 210;
    display: none;
    /*box-shadow: 5px 0px 10px -5px #585858;*/
}

div#im-floor-main {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
}

.floor-area {
    width: 99%;
    flex: 0 0 auto;
    margin: 0 5px 0 5px;
    padding-top: 5px;
}

.floor-area ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.floor-area ul li.selected {
    color: $black;
}

.floor-area ul li.active {
    color: $white;
    font-weight: 700;
    background-color: $black;
    border: solid 1px #ffffff;
}

.floor-area ul li {
    font-size: 12px;
    cursor: pointer;
    display: inline-block;
    color: $black;
    text-align: center;
    cursor: pointer;
    font-weight: 200;
    padding: 4px 0;
    width: 48%;
    font-weight: 700;
}

.floor-area ul li:hover {
    background-color: $black;
    color: $white;
}

.floor-tools {
    width: 100%;
    margin: 0;
    overflow-y: auto;
    padding-bottom: 2%;
    display: flex;
    flex-flow: column nowrap;
    flex: 0 0 auto;
}

[class^=slider] {
    display: inline-block;
    margin-bottom: 4px;
}

.slidercontain {
    width: 90%;
    margin-left: 5px;
    margin-bottom: 0 !important;
    color: $white;
    display: flex;
    flex-flow: row nowrap;
}

.width {
    float: left;
    font-weight: bold;
    font-size: 12px;
    margin-left: 10px;
    padding-right: 5px;
    color: $black;
}


.slider-center {
    width: 90%;
    /*height: 20px;*/
    float: left;
    display: flex;
    flex-flow: row nowrap;
    flex: 1 0 auto;
}

.slider-volume {
    width: 42% !important;
    width: 70% !important;
}

.slider-center input.value {
    position: relative;
    float: right;
    text-align: center;
    font-size: 12px;
    flex: 0 0 auto;
    min-width: 40px;
}

.feet {
    float: left;
    font-weight: bold;
    font-size: 14px;
    color: $black;
    fleX: 0 0 auto;
    margin-left: 10px;
}

div#sldWidth-slider {
    flex: 1 0 auto;
}

div#sldHeight-slider {
    flex: 1 0 auto;
}


.tool-ss {
    height: 50px;
    width: 100%;
    margin: 2% 2% 2% 3%;
}

#overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000;
    width: 100%;
    height: 100%;
    display: none;
    position: absolute;
    z-index: 997;
}

#modPop {
    width: 100%;
    max-width: 440px;
    background-color: #FFF;
    padding: 30px;
    z-index: 999;
    box-shadow: 0px 6px 15px 0px rgba(50, 50, 50, 0.5);
    overflow: hidden;
    overflow-y: auto;
    display: none;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 100px;
    border: solid 1px $outline;
}

#modWarning {
    background-color: #f3f3f3;
    position: absolute;
    padding: 25px;
    z-index: 999;
    box-shadow: 0px 6px 15px 0px rgba(50, 50, 50, 0.5);
    overflow: hidden;
    overflow-y: auto;
    display: none;
    position: absolute;
    margin: 0 auto;
    top: 100px;
    left: 0;
    right: 0;
    width: 75%;
    max-width: 460px;
}

#modWarning-headline {
    color: $black;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
}


#modWarning-text {
    font-size: 17px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 15px;
}

.modWarning-button {
    cursor: pointer;
    padding: 10px;
    background-color: $red;
    margin: 1vmin 1vmin 1vmin 0;
    text-align: center;
    color: $white !important;
    /* line-height: 2vmin; */
    font-size: 18px;
    font-weight: 200;
    width: auto;
    min-width: 75%;
    border: solid 1px $red;
}

.modWarning-button:hover {
    background-color: $dark-gray;
        border: solid 1px $dark-gray;
}

#modPop-headline {
    text-align: left;
    font-weight: 200;
    color: $black;
    float: left;
    width: 100%;
}

#modPop-headline span {
}

#modPop-newsurfacelist {
    color: $black;
    margin-bottom: 20px;
    font-size: 20px;
    display: flex;
    flex-flow: row wrap;
}

.modPop-close {
    height: 25px;
    width: 50px;
    color: #9d9d9d;
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 402px;
    display: none;
}

div#modPop-newsurfacelist span {
    width: 100%;
    margin-bottom: 13px;
    color: $outline;
    font-size: 32px;
    line-height: 32px;
    font-style: normal;
}

.btn-modPop {
    color: #ffffff;
    font-size: 12px;
    border: solid #fff 1px;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    width: auto;
    margin: 0 5px 0 1px;
    text-transform: uppercase;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJod…EiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+');
    background-color: $red;
    padding: 8px 10px;
    border: none;
}

.btn-modPop.dark {
    background: $gray;
    color: #fff;
}

.btn-modPop.grey {
    background: $outline;
    color: #fff;
    margin-bottom: 6px;
    font-weight: 400;
}

.mp {
    color: $black;
    font-size: 16px;
    line-height: 50px;
    font-weight: 700;
    display: inline-block;
    margin-right: 7px;
    /*vertical-align: middle;*/
}

.mp.grey {
    color: #999;
}

.modPop-rule-sm {
    border-bottom: 2px solid #000;
    width: 276px;
    margin: 19px auto;
}

.btnsave-purple {
    font-size: 14px;
    padding: 13px 15px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    color: $white;
    text-align: center;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: $red;
    margin-top: 7px;
    text-transform: uppercase;
    border-radius: 0px;
    box-sizing: border-box;
    font-size: 14px;
    width: 93%;
    border: solid 2px $red;
    font-size: 18px;
    font-weight: 200;
    width: auto;
    min-width: 93%;
    font-weight: 400;
}

.buttonWrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.btnsave-purple.active {
    font-size: 14px;
    padding: 13px 15px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    color: $white;
    text-align: left;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: $red;
    margin-top: 7px;
    text-transform: uppercase;
    border-radius: 0px;
    box-sizing: border-box;
    font-size: 14px;
    width: 93%;
    margin-right: 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: 200;
    text-transform: uppercase;
    text-align: center;
    font-weight: 400;
    border: 1px solid $red;
    transition: 50ms all ease-out;
}

.btnsave-purple.active:hover {
    /*background-color: $blue;*/
}

.ok.viz-button.span2.btnsave-purple.active.hvr-trim {
    background-color: $red !important;
}

/*.btnsave-purple.active:hover {
   background-color:$white !important;
}*/

.btnsave-purple.active.green {
    background-color: $dark-gray;
    color: $white;
    position: relative;
    border: none;
    border: solid 1px $dark-gray;
}

div#btnsaveAndContinue {
    background-color: $red;
    color: $white;
    position: relative;
    cursor: pointer;
    font-size: 18px;
    font-weight: 200;
    text-transform: uppercase;
    text-align: center;
    font-weight: 400;
    border: 1px solid $red;
    transition: 50ms all ease-out;
}

div#btnsaveAndContinue:hover {
    background-color: $blue;
    color: $white;
}

.btnsave-purple.viewing {
}

.btnsave-grey {
    font-size: 14px;
    padding: 13px 15px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    color: $white;
    text-align: left;
    border-radius: 5px;
    box-sizing: border-box;
    margin-top: 7px;
    text-transform: uppercase;
    border-radius: 0px;
    box-sizing: border-box;
    font-size: 14px;
    width: 93%;
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;
    border: solid 2px #969696;
    text-transform: uppercase;
    text-align: center;
    background-color: $dark-gray;
    border: 1px solid $dark-gray;
    transition: 50ms all ease-out;
}

.btnsave-grey:hover {
    /*background-color: $blue;
    color: $white;*/
}

hr.skinny {
    padding: 0;
}

.btnim-purple {
    font-size: 14px;
    padding: 13px 15px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    color: $white;
    text-align: center;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: $red;
    margin-top: 7px;
    text-transform: uppercase;
    border-radius: 0px;
    box-sizing: border-box;
    font-size: 14px;
    width: 93%;
    font-weight: 400;
    border: 1px solid $red;
    transition: 50ms all ease-out;
}

/*.btnim-purple:hover {
    background-color: $blue;
    color: $white;
}*/


.btnmapping {
    color: $black;
    display: flex;
    margin-right: 20px;
    cursor: pointer;
    font-size: 14px;
    flex-flow: row nowrap;
}

.output {
    color: #000;
    font-size: 14px;
    font-weight: bold;
    padding-top: 1px;
    margin-left: 5px;
    vertical-align: top;
    border: solid 1px $gray;
    border-radius: 5px;
    padding: 4px;
}

.currentSurfaceTitle {
    display: none;
    color: $outline;
    font-size: 32px;
    line-height: 32px;
    font-style: normal;
}


tool-fullscreen {
    height: 119px;
    width: 141px;
    background-image: url(/img/fullscreen.jpg);
    margin: 10px 0 10px 70px;
    border-radius: 5px;
    border: 1px solid #483698;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
    text-decoration: underline;
    line-height: 23px;
    cursor: pointer;
}

.vid-modal {
    background-color: #FFF;
    z-index: 1100;
    display: none;
    box-shadow: 0px 6px 15px 0px rgba(50, 50, 50, 0.5);
    background-repeat: no-repeat;
    background-repeat: repeat-x;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 2vmin;
}

.vid-modal.outline {
    display: none;
}

.vid-modal.paint {
    display: none;
}

.vid-modal.wand {
    display: none;
}

.vid-modal.erase {
    display: none;
}

.vid-modal.perspective {
    display: none;
}


.vid-modal-headline {
    text-align: left;
    font-size: 36px;
    font-weight: 400;
    color: $black;
    margin-right: 50px;
    font-style: normal;
}

.vid-modal-close {
    color: $black;
    cursor: pointer;
    font-size: 0;
    position: absolute;
    right: 10px;
    top: 10px;
}


.vid-modal-close:hover {
    color: #333333;
}


.vid-modal-close img {
    width: 25px;
    height: 25px;
}

.vid-modal-box {
    width: 78vmin;
    height: 52vmin;
    margin: 21px auto;
    text-align: center;
    display: block;
    background-image: url(/img/vid/Crop.gif);
    background-size: cover;
    -o-object-fit: cover;
    object-fit: cover;
}

.vid-modal-box.outline {
    background-image: url(/img/vid/outline.gif);
    background-size: cover;
    -o-object-fit: cover;
    object-fit: cover;
}

.vid-modal-box.paint {
    background-image: url(/img/vid/paint.gif);
    background-size: cover;
    -o-object-fit: cover;
    object-fit: cover;
}

.vid-modal-box.wand {
    background-image: url(/img/vid/fill.gif);
    background-size: cover;
    -o-object-fit: cover;
    object-fit: cover;
}

.vid-modal-box.erase {
    background-image: url(/img/vid/erase.gif);
    background-size: cover;
    -o-object-fit: cover;
    object-fit: cover;
}

.vid-modal-box.perspective {
    background-image: url(/img/vid/perspective.gif);
    background-size: cover;
    -o-object-fit: cover;
    object-fit: cover;
}


.vid-text {
    color: $black;
    font-weight: 400;
    display: block;
    text-align: left;
    font-size: 14px;
}

/*overlay*/

#vid-overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.76);
    width: 100%;
    height: 100%;
    display: none;
    z-index: 1099;
}



/* ChamModal  */
.chamModal {
    background: url("/ChamWebTools/JS/ChamModal/1/overlay.png") repeat left top;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    display: none;
    z-index: 999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    user-drag: none;
}

.chamModal .modalInner {
    position: absolute;
    background: #e5e5e5;
    border: 2px solid #cfcfcf;
    left: 0;
    top: 100px;
    margin: 0 auto;
    right: 0;
}

.chamModal .modalInner.Draggable {
    background: #ffffff;
    border: solid 1px $outline;
    color: #555;
    left: 0;
    top: 100px;
    margin: 0 auto;
    right: 0;
    border-radius: 0px;
}

.chamModal .modalTitleBar {
    width: 100%;
    height: 40px;
    line-height: 40px;
    border: none;
    background: none;
    text-align: right;
    z-index: 20;
}

.chamModal .modalTitleBar .title, .chamModal .modalTitleBar .closeBTN {
    display: inline-block;
    zoom: 1;
    *display: inline;
    vertical-align: top;
    position: relative;
    color: #fff;
    padding: 0;
    margin: 0;
}

.chamModal .modalTitleBar .title {
    font-size: 20px;
    text-align: left;
    width: auto;
    float: left;
    text-indent: 10px;
}

.chamModal .modalTitleBar .closeBTN {
    font-size: 50px !important;
    cursor: pointer;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #cccccc;
    top: 1px;
    right: 3px;
    font-weight: 400;
    color: $outline;
}

.chamModal .modalTitleBar .closeBTN:hover {
    color: #333333;
}



.chamModal .modalContent {
    padding: 10px;
    margin-left: 2%;
    margin-bottom: 3%;
}


.chamModal .modalContent .button {
    cursor: pointer;
}

.chamModal .modalContent .question {
    margin-bottom: 12px;
    font-size: 16px;
    text-align: center;
    color: $black;
}


.rightPadding {
    float: right;
    padding-top: 2px;
}

.newSurface {
    font-size: 14px;
    color: $black;
    text-transform: none !important;
    font-weight: 400;
}

.newSurface div {
    margin-bottom: 15px;
}

.newSurface div:last-child {
    margin-bottom: 0;
}

.gone {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
}

.gone i {
    font-size: 18px;
    right: 15px;
    top: 15px;
    color: #cccccc;
}


.gone i:hover {
    color: #333333 !important;
}

.gone img {
    width: 16%;
    height: 10%;
    float: right;
}


.goright i {
    padding-left: 6px;
    font-size: 17px;
}


#panTip {
    width: 100%;
    height: auto;
    display: block;
    padding: 5px 5px 5px 10px;
    color: $black;
    font-size: 11px;
    box-sizing: border-box;
}

.panIcon {
    float: left;
    padding-right: 10px;
}

.panIcon i {
    font-size: 32px;
    color: $outline;
}


.widthSelect {
    position: absolute;
    width: 140px !important;
    height: 155px;
    z-index: 999;
    border: 1px solid #fff;
    display: none;
    background-color: $white;
    margin-top: 7px;
    margin-left: 0px;
    clear: both;
}

.widthSelect li {
    height: 49px !important;
    padding: 0px !important;
    clear: both;
}


.slider-box {
    margin: 0 auto !important;
    width: 100%;
    padding-left: 10px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
}


/*THIS WILL MAKE IM IMAGE RESPONSIVE */

.im-box {
    width: 100% !important;
}

#im-view, .im-overlay {
    width: 100% !important;
}

.im-overlay {
    /*max-height: 875px;
    overflow-y: auto;
    overflow-x: hidden;*/
}

#map-view {
    width: 85% !important;
    background-color: #ffffff;
}

#mapping-box {
    width: 100% !important;
}


#im-view {
    width: 100% !important;
}

.imap, #im-tools {
    width: 25% !important;
}

#viz, #viz .ImageMapper, #viz .ImageMapper canvas {
    width: 100% !important;
}
/* , #viz .ImageMapper canvas */

.mobile-help {
    display: none;
}


/*END THIS WILL MAKE IM IMAGE RESPONSIVE */



/* MISC STYLES
   ========================================================================== */


.clearfix {
    display: inline-block;
}

.clearfix:after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden;
}

.clearfix {
    display: block;
}

#my-projects {
    display: none;
}


/* Simple Slider
   ========================================================================== */

.slider {
    width: 300px;
}

.slider > .dragger {
    background: #8DCA09;
    background: -webkit-linear-gradient(top, #8DCA09, #72A307);
    background: -moz-linear-gradient(top, #8DCA09, #72A307);
    background: linear-gradient(top, #8DCA09, #72A307);
    -webkit-box-shadow: inset 0 2px 2px rgba(255,255,255,0.5), 0 2px 8px rgba(0,0,0,0.2);
    -moz-box-shadow: inset 0 2px 2px rgba(255,255,255,0.5), 0 2px 8px rgba(0,0,0,0.2);
    box-shadow: inset 0 2px 2px rgba(255,255,255,0.5), 0 2px 8px rgba(0,0,0,0.2);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    border: 1px solid #496805;
    width: 16px;
    height: 16px;
}

.slider > .dragger:hover {
    background: -webkit-linear-gradient(top, #8DCA09, #8DCA09);
}


.slider > .track, .slider > .highlight-track {
    background: #ccc;
    background: -webkit-linear-gradient(top, #bbb, #ddd);
    background: -moz-linear-gradient(top, #bbb, #ddd);
    background: linear-gradient(top, #bbb, #ddd);
    -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,0.1);
    -moz-box-shadow: inset 0 2px 4px rgba(0,0,0,0.1);
    box-shadow: inset 0 2px 4px rgba(0,0,0,0.1);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    border: 1px solid #aaa;
    height: 4px;
}

.slider > .highlight-track {
    background-color: #8DCA09;
    background: -webkit-linear-gradient(top, #8DCA09, #72A307);
    background: -moz-linear-gradient(top, #8DCA09, #72A307);
    background: linear-gradient(top, #8DCA09, #72A307);
    border-color: #496805;
}


// VOLUME
.slider-volume {
    width: 80%;
}

.slider-volume > .dragger {
    width: 18px;
    height: 18px;
    margin: 0 auto;
    border: 1px solid rgba(255,255,255,0.6);
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: $red;
}

.slider-volume > .track, .slider-volume > .highlight-track {
    height: 8px;
    background: #FFFFFF;
    border: 1px solid #999999;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}



.slider-volume > .highlight-track {
    background-color: #c5c5c5;
    background: -moz-linear-gradient(top, #c5c5c5, #a2a2a2);
    background: -webkit-linear-gradient(top, #c5c5c5, #a2a2a2);
    background: linear-gradient(top, #c5c5c5, #a2a2a2);
}


@include breakpoint(phone portrait) {
    html {
        position: relative !important;
        /*overflow-y: hidden;*/
    }

    body {
        /*height: auto !important;*/
        background-color: #F2F2F2;
        position: absolute !important;
        top: 0;
    }

    section#MAIN {
        max-height: none;
        background-color: #ffffff;
    }

    #im-container {
        display: block;
        overflow-x: hidden;
        overflow-y: auto;
    }

    #im-tools {
        width: 100% !important;
        position: static;

        > div {
            position: static;
        }
    }

    #map-box {
        background-color: #F2F2F2;
        position: static;
        display: none;
    }

    #map-view {
        width: 100% !important;
        float: none;
    }

    .choose-tool ul li {
        width: 24% !important;
        position: relative;

        &:last-child .widthSelect {
            right: 0;
        }
    }

    .tool-fullscreen, .tool-text {
        display: none;
    }

    #viz {
        z-index: 100 !important;
    }

    .map-view-top {
        margin: 0;
        padding: 0 0 10px;
        background-color: $white;

        .btnChangeRoom {
            text-align: center;
            -moz-border-radius: 5px;
            box-sizing: border-box;
            display: inline-block;
            width: auto;
            font-size: 14px;
            color: #000000;
            cursor: pointer;
            text-transform: uppercase;
            padding: 10px 30px;
            background-color: #ffffff;
            border: solid 1px #000000;
            margin: 1px;
        }
    }

    #mapping-box {
        border: 2px solid $white;
    }

    .mapping-nav {
        margin: 5px 0 0 0;
        padding: 0 10px;
        justify-content: center;
        flex-wrap: wrap;
        background-color: $white;
    }

    .mapping-nav-left {
        max-width: 100%;
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        > div {
            margin-bottom: 5px;
        }
    }

    .mapping-nav-right {
        margin-bottom: 15px;

        .btnmapping:last-child {
            margin-right: 0;
        }
    }

    .floor-area {
        width: 100%;
        margin: 0;

        ul {
            display: flex;
        }

        ul li {
            width: 50%;
            padding: 6px 0;
        }
    }

    .floor-tools {
        padding-bottom: 0;
        overflow: visible;
    }

    .choose-tool {
        margin: 4px 0 15px;

        ul li:first-child {
        }
    }

    .tool-sc {
        margin-bottom: 10px;
    }

    .tool-ss {
        height: auto;
        margin: 6px 0 8px;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    .perspective-fullscreen {
        display: none;
    }

    #btnAddEditSurface {
        margin-top: 10px;
    }
    /* Crop page mobile */
    #im-box, #im-view, .im-view-top {
        background-color: #F2F2F2;
    }

    #im-box {
        width: 100%;
        margin-left: 0;
        position: static;
    }

    #im-view {
        overflow: hidden;
    }

    .crop-box p {
        margin-bottom: 15px;
    }

    .crop-fullscreen {
        /*display: none;*/
        height: 100px;
    }

    .btnim-purple {
        margin: 0 auto;
        font-size: 14px !important;
        display: block;
    }

    #btnChangeRoom, #btnRotate {
        margin-bottom: 5px;
    }

    .im-view-top .ctr {
        max-width: 93%;
        margin: 10px auto 20px;
        padding: 0;

        .btnim {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .im-overlay {
        text-align: center;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;

        .jcrop-holder {
            display: inline-block;
        }
    }

    #panTip {
        display: none;
    }
    /* Mobile Help ? Marks */
    .title-wrapper {
        position: relative;
    }

    .mobile-help {
        width: auto !important;
        height: auto !important;
        color: #CE1126;
        font-size: 1.5em;
        font-weight: bold;
        line-height: 1;
        background-image: none;
        display: block !important;
        position: absolute;
        top: 0;
        right: 10px;
    }

    #zoom-controls {
        max-width: 300px;
    }


    .color-slider .slider-volume {
        width: 90% !important;
    }
}

@include breakpoint(phone landscape) {
    body {
        height: 100% !important;
        /*overflow-y: auto;*/
        min-height: 380px !important;
    }

    #my-projects {
        display: none;
    }

    #panTip, .tool-text {
        display: none;
    }
 

    #btnsaveAndContinue .angle-right {
        display: none;
    }

    .floor-area ul li {
        font-size: 12px;
    }

    .area {
        font-size: 16px;
    }

    .choose-tool {
        margin: 10px 0;
    }

    div.floor-tools.outline .tool-fullscreen {
        height: 100px;
    }

    .mapping-nav {
        flex-flow: column nowrap;
    }

    .mapping-nav-right {
        margin-top: 20px;
    }

    .btnim {
        padding: 10px 20px;
    }

    div#im-floor {
        height: auto;
    }

    .btnsave-purple {
        margin-bottom: 10px;
    }

    div#im-angle {
        height: 160%;
    }

    .slider-center {
        flex-flow: column nowrap;
    }

    .slider-volume {
        width: 90% !important;
    }

    .btnsave-grey {
        padding: 10px 5px;
        font-size: 11px;
    }

    .btnsave-purple.active {
        font-size: 11px;
    }

    .perspective-fullscreen {
        height: 100px;
    }

    .step {
        font-size: 15px;
    }

    .slidercontain {
        align-items: flex-end;
    }

    .widthSelect {
        width: 80px !important;
        height: 84px !important;
    }

    .widthSelect li {
        height: 27px !important;
    }

    ul.paint-tool li, ul.erase-tool li {
        width: 100%;
    }

    ul.paint-tool li img, ul.erase-tool li img {
        width: 100%;
    }
}

@include breakpoint(tablet portrait) {

    .mapping-nav {
        flex-flow: column nowrap;
    }

    .mapping-nav-right {
        margin-top: 20px;
    }

    .slider-volume {
        width: auto !important;
    }

    .slider-center {
        width: auto !important;
    }

    .color-slider .slider-volume {
        width: 90% !important;
    }
}

@include breakpoint(tablet landscape) {
    .crop-fullscreen {
        width: 100%;
    }

    .btnim {
        padding: 10px 20px;
    }

    .btnsave-grey {
        font-size: 12px;
    }

    .btnsave-purple.active {
        font-size: 12px;
    }

    .floor-area ul li {
        font-size: 11px;
    }
}

@include breakpoint(only tablet) {
    #panTip {
        display: none;
    }
}

div.wait {
    background-image: url(../../img/25.gif) !important;
    background-color: none !important;
    z-index: 10000;
    position: absolute;
    text-align: center;
    background-repeat: no-repeat !important;
    background: rgba(0, 0, 0, 0.75);
    width: 100% !important;
    height: 100% !important;
    left: 0 !important;
    top: 0 !important;
    background-position: center;
}


/*MODAL HIDE*/

#successOverlay {
    display: none;
}

#congratsOverlay {
    display: none;
}

#confirmOverlay {
    display: none;
}

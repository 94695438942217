/* ----------------------------------------------------------------
My Projects
-----------------------------------------------------------------*/

#my-projects > div {
  width: 100% !important;
  margin: 2% 0;
  max-width: 600px;
}

#my-projects .innerFormWrapper {
  box-sizing: border-box;
}

#my-projects .formTitle h2,
#my-projects h2.forgotTitle,
#my-projects h2.forgotTitle label,
#my-projects h2.saveTitle,
#my-projects h2.saveTitle label {
  margin-bottom: 1.5vmin;
  padding: 0;
  color: #434343;
  font-size: 2.2vmin;
  font-weight: bold;
}

#my-projects h2.forgotTitle span {
  color: #5A5A5A;
  font-size: 0.7em;
  font-weight: normal;
  display: block;
}

#my-projects .formBTNS ul {
  margin: 2vmin 0 0;
  padding: 0;
  list-style: none;
}

#my-projects .linkBTNS {
  color: #5A5A5A;
  display: inline-block;
  cursor: pointer;
}

#my-projects .linkBTNS:hover {
  text-decoration: underline;
}

#my-projects .loginBTN,
#my-projects .forgotBTN,
#my-projects .regBTN {
  margin-bottom: 1.5vmin;
}

#my-projects .errors {
  margin-bottom: 2vmin;
}

#my-projects .TitleBar {
  display: flex;
  flex-wrap: wrap;
}

#my-projects .TitleBar h2 {
  flex: 2;
}

#my-projects .titleBarBTNS {
  display: flex;
  align-items: center;
}

#my-projects .titleBarBTNS .buttons {
  margin-right: 1vmin;
  font-size: 0.9em;
  cursor: pointer;
}

#my-projects .titleBarBTNS .buttons:last-child {
  margin-right: 0;
}

#my-projects .closeBTN {
  margin: 0 !important;
  color: #434343;
  font-size: 0 !important;
  font-weight: 400;
  line-height: 1;
  position: relative;
  cursor: pointer;
}

#my-projects .closeBTN:before {
  content: 'X';
  margin-left: 1vmin;
  font-size: 3vh;
  cursor: pointer;
}

#my-projects .myProjectBTNsUL {
  margin: 0;
  padding: 0;
  list-style: none;
}

#my-projects .myProjectBTNsUL li {
  margin-bottom: 4px;
}

#my-projects .projectsForm .projectWrapper {
  width: 100%;
  margin-bottom: 3vmin;
  display: table;
}

li.Edit.prj-viz {
  padding: 10px;
    color: white;
    text-transform: uppercase;
    background-color: #808080;
    border: none;
    display: inline-block;
    letter-spacing: 1px;
    cursor: pointer;
}

#my-projects .projectsForm > img {
  display: table-cell;
  vertical-align: top;
}

#my-projects .projectsForm .innerWrapper {
  width: 100%;
  padding-left: 4vmin;
  display: table-cell;
  vertical-align: top;
}

#my-projects .projectsForm .projectTitle {
  margin-bottom: 4px;
  padding-left: 0;
  line-height: 1;
}

#my-projects .projectsForm .projectDate {
  margin-bottom: 1vmin;
  font-size: 0.8em;
}

#uploadOverlay, #modalOverlay, #successOverlay, #congratsOverlay, #confirmOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.78);
  display: none;
  z-index: 3010;
}

﻿@charset "utf-8";

@-ms-viewport {
    width: device-width;
}

*, *:before, *:after {
    box-sizing: inherit;
}

html {
    color: #434343;
    font-size: 1em;
    line-height: 1.4;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}


/* Remove text-shadow in selection highlight */

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}


/* A better looking default horizontal rule */

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}


/* Remove the gap between element and the bottom of their containers */

audio, canvas, iframe, img, svg, video {
    vertical-align: middle;
}


/* Remove default fieldset styles. */

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}


/* Allow only vertical resizing of textareas. */

textarea {
    resize: vertical;
}

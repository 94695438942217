@charset "UTF-8";
@-ms-viewport {
  width: device-width;
}
*, *:before, *:after {
  box-sizing: inherit;
}

html {
  color: #434343;
  font-size: 1em;
  line-height: 1.4;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* Remove text-shadow in selection highlight */
::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

/* A better looking default horizontal rule */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/* Remove the gap between element and the bottom of their containers */
audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

/* Remove default fieldset styles. */
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/* Allow only vertical resizing of textareas. */
textarea {
  resize: vertical;
}

/* Clearfix: contain floats */
.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

/* Hack to fix ie adding extra spacing around scaled svgs */
img[src$=".svg"] {
  width: 100%;
}

/* Portrait iphone 6 and above */
@media only screen and (min-device-width: 375px) and (max-device-width: 1024px) and (orientation: portrait) {
  body.non-mobile #rotate-overlay {
    display: block;
  }
}
body.mobile #mobile-overlay {
  display: block;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

/*----------------------------------------------------------------
Ghost Center
-----------------------------------------------------------------*/
.ghost-center {
  text-align: center;
  white-space: nowrap;
}

.ghost-center:before {
  content: "";
  width: 0;
  height: 100%;
  margin-left: -0.2em;
  /* Adjust for spacing between inline-block elements. */
  display: inline-block;
  vertical-align: middle;
}

.ghost-center > div {
  text-align: left;
  white-space: normal;
  display: inline-block;
  vertical-align: middle;
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .ghost-center > div {
    text-align: left;
    white-space: normal;
    display: inline-block;
    vertical-align: top;
    margin-top: 25px;
  }
}
/* ==========================================================================
    GLOBAL
   ========================================================================== */
body, html, #MAIN {
  width: 100%;
  height: 100% !important;
}

#MAIN {
  display: flex;
  flex-flow: column nowrap;
  padding: 10px 20px;
  min-height: 0;
}

html {
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  min-height: 750px;
}

h1, h2, h3, h4, h5, h6, p {
  padding: 0;
  margin: 0;
  font-weight: normal;
}

h1 {
  padding: 0;
  font-size: 36px;
}

h2 {
  font-size: 3vh;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

header {
  display: flex;
  height: auto;
  width: 100%;
  flex: 0 0 auto;
  margin-bottom: 20px;
}

main {
  height: auto;
  flex: 1 1 auto;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  min-height: 0;
}

main section {
  width: 100%;
  height: 100%;
  /*position: absolute;
  top: 0;
  left: 0;*/
}

.alertify-logs > * {
  padding: 12px 24px;
  color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 1px;
  transition: 0.2s all;
  display: block !important;
}
.alertify-logs > *, .alertify-logs > *.default {
  background: rgba(0, 0, 0, 0.8);
}
.alertify-logs > *.error {
  background: rgba(244, 67, 54, 0.8);
}
.alertify-logs > *.success {
  background: rgba(76, 175, 80, 0.9);
}

.alertify {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
}
.alertify.hide {
  opacity: 0;
  pointer-events: none;
}
.alertify, .alertify.show {
  box-sizing: border-box;
  transition: all 0.33s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.alertify, .alertify * {
  box-sizing: border-box;
}
.alertify .dialog {
  padding: 12px;
}
.alertify .dialog, .alertify .alert {
  width: 100%;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.alertify .dialog > *, .alertify .alert > * {
  width: 400px;
  max-width: 95%;
  margin: 0 auto;
  text-align: center;
  padding: 12px;
  background: #fff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.098), 0 1px 10px 0 rgba(0, 0, 0, 0.084);
}
.alertify .dialog .msg, .alertify .alert .msg {
  padding: 12px;
  margin-bottom: 12px;
  margin: 0;
  text-align: left;
}
.alertify .dialog input:not(.form-control), .alertify .alert input:not(.form-control) {
  margin-bottom: 15px;
  width: 100%;
  font-size: 100%;
  padding: 12px;
}
.alertify .dialog input:not(.form-control):focus, .alertify .alert input:not(.form-control):focus {
  outline-offset: -2px;
}
.alertify .dialog nav, .alertify .alert nav {
  text-align: right;
}
.alertify .dialog nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button), .alertify .alert nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button) {
  background: transparent;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  position: relative;
  outline: 0;
  border: 0;
  display: inline-block;
  align-items: center;
  padding: 0 6px;
  margin: 6px 8px;
  line-height: 36px;
  min-height: 36px;
  white-space: nowrap;
  min-width: 88px;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 2px;
}
.alertify .dialog nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):hover, .alertify .dialog nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):active, .alertify .alert nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):hover, .alertify .alert nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):active {
  background-color: rgba(0, 0, 0, 0.05);
}
.alertify .dialog nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):focus, .alertify .alert nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):focus {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.alertify .dialog nav button.btn, .alertify .alert nav button.btn {
  margin: 6px 4px;
}

.alertify-logs {
  position: fixed;
  z-index: 99999;
}
.alertify-logs.bottom, .alertify-logs:not(.top) {
  bottom: 16px;
}
.alertify-logs.left, .alertify-logs:not(.right) {
  left: 16px;
}
.alertify-logs.left > *, .alertify-logs:not(.right) > * {
  float: left;
  transform: translate3d(0, 0, 0);
  height: auto;
}
.alertify-logs.left > *.show, .alertify-logs:not(.right) > *.show {
  left: 0;
}
.alertify-logs.left > *, .alertify-logs.left > *.hide, .alertify-logs:not(.right) > *, .alertify-logs:not(.right) > *.hide {
  left: -110%;
}
.alertify-logs.right {
  right: 16px;
}
.alertify-logs.right > * {
  float: right;
  transform: translate3d(0, 0, 0);
}
.alertify-logs.right > *.show {
  right: 0;
  opacity: 1;
}
.alertify-logs.right > *, .alertify-logs.right > *.hide {
  right: -110%;
  opacity: 0;
}
.alertify-logs.top {
  top: 0;
}
.alertify-logs > * {
  box-sizing: border-box;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  position: relative;
  clear: both;
  backface-visibility: hidden;
  perspective: 1000;
}
.alertify-logs > * {
  max-height: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.alertify-logs > *.show {
  margin-top: 12px;
  opacity: 1;
  max-height: 1000px;
  padding: 12px;
  pointer-events: auto;
}

/* ----------------------------------------------------------------
Landing
-----------------------------------------------------------------*/
.landing {
  display: none;
}

header nav.landing {
  padding: 0 2.9% 0 0;
  font-size: 2.5vh;
}

#landing-projects {
  cursor: pointer;
}

section.landing {
  width: 100%;
  height: 100%;
}

section.landing div.upload {
  width: 97%;
  margin: 2% auto 0 auto;
  position: relative;
  cursor: pointer;
}

section.landing div.callout {
  cursor: pointer;
}

#roomscene-data {
  padding: 0;
  width: 100%;
  margin: 0 auto;
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  overflow: hidden;
}

/*#roomscene-data li {
  width: 25%;
  margin: 0;
  padding: 0;
}

#roomscene-data img {
  width: 100%;
  height: 25vw;
  background-image: url(/img/balls.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;
}
#roomscene-data img[src] {
  width: 100%;
  height: auto;
  background-image: none;
}*/
/* ----------------------------------------------------------------
Scenes
-----------------------------------------------------------------*/
section.scenes {
  /*display: none;*/
  width: 100%;
  height: auto;
  display: flex !important;
  flex-flow: column nowrap;
  flex: 1 1 auto;
}

div.scene-wrapper {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0 auto;
  overflow-y: hidden;
  overflow-x: hidden;
  flex: 1 1 auto;
  order: 2;
}

#roomscene-data {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

#roomscene-data li {
  margin: 5px;
  flex: 0 0 23.2%;
  padding: 1px;
  font-size: 2vmin;
  position: relative;
  /*&:nth-child(4n+4) {
      margin-right: 0;
  }

  &:nth-child(9) {
      margin-bottom: 0;
  }

  &:nth-child(10) {
      margin-bottom: 0;
  }

  &:nth-child(11) {
      margin-bottom: 0;
  }*/
}
#roomscene-data li:hover .viz-overlay {
  display: flex;
}

.viz-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*display: flex;*/
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: none;
}
.viz-overlay p {
  font-size: 16px;
  color: #ffffff;
}

#roomscene-data img {
  width: 100%;
  height: 100%;
  /*background-image: url(/img/balls.svg);
  background-repeat: no-repeat;
  background-size: contain;*/
  background-position: center;
  cursor: pointer;
}

#roomscene-data img[src] {
  /*width: 100%;*/
  /*height: auto;*/
  background-image: none;
}

.scenes-header {
  background-color: #ededed;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  padding: 16px 0;
  flex: 0 0 auto;
}
.scenes-header img {
  width: 100%;
  max-width: 68px;
  margin-right: 20px;
}
.scenes-header h1 {
  font-weight: 700;
  flex: 1 1 auto;
  text-align: center;
  letter-spacing: 1px;
  line-height: 1;
}
.scenes-header div#landing-projects {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
}
.scenes-header div#landing-projects p {
  font-size: 16px;
  letter-spacing: 1px;
}

.logo-container {
  display: flex;
  flex-flow: row nowrap;
  width: auto;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  min-width: 24%;
}

.scenes-body {
  display: flex;
  flex-flow: column nowrap;
  width: 61%;
  margin: 0 auto;
  flex: 1 0 auto;
}

.viz-description {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  flex: 0 0 auto;
  margin: 30px 0;
}
.viz-description h3 {
  color: #ED1B24;
  font-size: 20px;
  font-weight: 600;
}

div#upload-link {
  cursor: pointer;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  text-decoration: none;
  color: #434343;
  border: solid 1px #797979;
  padding: 20px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  max-width: 96.5%;
}
div#upload-link:hover img.rotate-center {
  -webkit-animation: rotate-center 0.6s ease-in-out both;
  animation: rotate-center 0.6s ease-in-out both;
}
div#upload-link img {
  width: 77px;
  flex: 0 0 auto;
  margin-right: 10px;
}
div#upload-link div {
  /*flex: 0 0 35%;*/
  flex: 0 0 49%;
}
div#upload-link div h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #ED1B24;
}
div#upload-link div p {
  font-size: 16px;
  line-height: 1;
}

/* ----------------------------------------------
 * Generated by Animista on 2018-2-5 10:11:51
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 767px) and (orientation: portrait) {
  .viz-description h3 {
    font-size: 16px;
  }
  .scenes-header {
    flex-flow: column nowrap;
  }
  .scenes-header div#landing-projects {
    position: relative !important;
    align-self: flex-end;
    margin-top: 10px;
    transform: none;
    top: auto;
    right: auto;
    margin-right: 10px;
  }
  div#upload-link {
    order: -1;
    margin: 0 0 10px 0;
    padding: 10px;
    /*flex-flow: column nowrap;*/
  }
  div#upload-link img {
    /*margin: 0 0 10px 0;*/
  }
  div#upload-link div {
    flex: 0 0 71%;
  }
  div#upload-link div h4, div#upload-link div p {
    text-align: left;
    margin-right: 10px;
  }
  .scene-wrapper {
    overflow: visible;
  }
  #roomscene-data li {
    margin: 8px !important;
    flex: 0 0 45%;
  }
  #roomscene-data li:nth-child(9) {
    margin-bottom: 0;
  }
  #roomscene-data li:nth-child(10) {
    margin-bottom: 0;
  }
  #roomscene-data li:nth-child(11) {
    margin-bottom: 0;
  }
  .viz-description {
    margin: 15px 0;
  }
  .scenes-header h1 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) and (orientation: landscape) {
  div#upload-link {
    padding: 15px;
  }
  div#upload-link div {
    flex: 0 0 85%;
  }
  #roomscene-data li {
    margin: 0 20px 20px 0;
    flex: 0 0 30%;
    /*&:nth-child(3n+3) {
        margin-right: 0 !important;
    }*/
    /*&:nth-child(10) {
        margin-bottom: 0;
    }*/
  }
  #roomscene-data li:nth-child(4n+4) {
    margin-right: 20px;
  }
  #roomscene-data li:nth-child(9) {
    margin-bottom: 20px;
  }
  #roomscene-data li:nth-child(11) {
    margin-bottom: 0;
  }
  div#upload-link {
    order: -1;
    margin-top: 0;
    margin-bottom: 15px;
  }
  .viz-description {
    margin: 15px 0;
  }
  .scenes-header h1 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 1024px) {
  .scenes-body {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  #roomscene-data li {
    flex: 0 0 23.5%;
  }
  div#upload-link div {
    flex: 0 0 57%;
  }
  .scene-wrapper {
    overflow: visible;
  }
  #roomscene-data li {
    margin: 0 20px 20px 0;
    flex: 0 0 31.2%;
  }
  #roomscene-data li:nth-child(4n+4) {
    margin-right: 20px;
  }
  #roomscene-data li:nth-child(3n+3) {
    margin-right: 0 !important;
  }
  #roomscene-data li:nth-child(9) {
    margin-bottom: 20px;
  }
  #roomscene-data li:nth-child(10) {
    margin-bottom: 0;
  }
  #roomscene-data li:nth-child(11) {
    margin-bottom: 0;
  }
  .scenes-header h1 {
    font-size: 22px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  div#upload-link div {
    flex: 0 0 42%;
  }
  .scenes-header h1 {
    font-size: 26px;
  }
}
/* ----------------------------------------------------------------
Visualizer
-----------------------------------------------------------------*/
.visualizer-wrapper {
  display: none;
  width: 100%;
  height: auto;
  flex: 1 1 auto;
  display: flex !important;
  flex-flow: column nowrap;
  min-height: 0;
}

header {
  position: relative;
}

header .landing, header .visualizer-wrapper {
  width: 100%;
  height: auto;
  /*position: absolute;
    top: 0;
    left: 0;*/
  flex: 0 0 auto;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: #ededed;
  padding: 10px;
}

header .landing, header .visualizer {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-flow: row nowrap;
}

header nav.visualizer div {
  display: inline-block;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  margin-right: 30px;
}

header nav.visualizer div:last-child {
  margin-right: 0;
}

header nav.visualizer i.fa {
  display: block;
  font-size: 2.5vh;
  cursor: pointer;
}

section.visualizer {
  width: 100%;
  height: auto;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  min-height: 0;
}

div.visualizer-pane {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  min-height: 0;
}

/*div.product-menu {
    width: 31.5%;
    height: 100%;
    position: relative;
    overflow: hidden;
}*/
.viz-holder {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
}

#visualizer {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  flex: 1 1 auto;
}

#visualizer-controls {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  z-index: 3000;
}

#visualizer-controls button {
  margin-right: 2px;
  padding: 6px 8px;
  border: none;
  cursor: pointer;
  font-size: 2vmin;
}

#visualizer-controls button i {
  cursor: pointer;
}

#visualizer-controls button div {
  font-size: 0.8em;
  font-weight: bold;
  cursor: pointer;
}

#visualizer-controls button:focus {
  outline: none;
}

.footer {
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
}
.footer div#print {
  height: 50px;
  background-color: #ed1b24;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  flex: 0 0 10%;
}
.footer div#print i {
  font-size: 33px;
  margin-right: 15px;
  color: #ffffff;
}
.footer div#print p {
  color: #ffffff;
  font-size: 14px;
}
.footer nav#share-icons {
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 30%;
  justify-content: center;
}
.footer nav#share-icons a, .footer nav#share-icons div {
  width: 35px;
  min-width: 35px;
  height: 35px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
}
.footer nav#share-icons a i, .footer nav#share-icons div i {
  line-height: 1;
}
.footer nav#share-icons div {
  margin-right: 10px;
}
.footer nav#share-icons div#facebookLink {
  border: solid 2px #3b5998;
}
.footer nav#share-icons div#facebookLink img {
  width: 19px;
}
.footer nav#share-icons div#twitterLink {
  border: solid 2px #1da1f2;
}
.footer nav#share-icons div#twitterLink img {
  width: 20px;
}
.footer nav#share-icons div#pinterestLink {
  border: solid 2px #BD081C;
}
.footer nav#share-icons div#pinterestLink img {
  width: 19px;
}
.footer nav#share-icons div#email-btn {
  border: solid 2px #0a703f;
}
.footer nav#share-icons div#email-btn img {
  width: 20px;
}

div#calc-button {
  background-color: #ed1b24;
  height: 50px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}
div#calc-button i {
  font-size: 28px;
  margin-right: 15px;
  color: #ffffff;
}
div#calc-button p {
  color: #ffffff;
  font-size: 14px;
}

div#visualizer-zoom {
  position: absolute;
  z-index: 9999;
  bottom: 10px;
  left: 10px;
}
div#visualizer-zoom button {
  background-color: transparent;
  border: none;
  width: 40px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
div#visualizer-zoom button:first-child {
  margin-right: 10px;
}

div#visualizer-undo {
  position: absolute;
  z-index: 9999;
  bottom: 10px;
  right: 10px;
}
div#visualizer-undo button {
  background-color: transparent;
  border: none;
  width: 40px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
div#visualizer-undo button:first-child {
  margin-right: 10px;
}

.surface-menu {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
}
.surface-menu ul {
  display: flex;
  flex-flow: row nowrap;
}
.surface-menu ul li {
  background-color: #434343;
  color: #ffffff;
  font-size: 15px;
  margin-right: 10px;
  width: 145px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
}
.surface-menu ul li:last-child {
  margin-right: 0;
}
.surface-menu ul li.active {
  background-color: #ed1b24;
}

div#brick-menu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  height: 100%;
  /*width: 560px;*/
  width: 594px;
  background-color: #ffffff;
  display: flex;
  flex-flow: row nowrap;
  box-shadow: 1px -1px 5px 0px #333333;
  border: solid 1px rgba(51, 51, 51, 0.18);
  transform: translateX(-105%);
  transition: 0.4s transform ease-out;
}
div#brick-menu.active {
  transform: translateX(0);
  transition: 0.4s transform ease-out;
  z-index: 999999;
}

.side-surface {
  background-color: #ededed;
  padding: 34px 10px 10px 10px;
  flex: 0 0 auto;
  overflow-y: auto;
}
.side-surface ul {
  display: flex;
  flex-flow: column nowrap;
}
.side-surface ul li {
  width: 150px;
  height: 40px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: #434343;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 15px;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: center;
}
.side-surface ul li:last-child {
  margin-bottom: 0;
}
.side-surface ul li.active {
  background-color: #ed1b24;
}

div.product-menu {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  padding: 10px;
  min-height: 0;
  width: 100%;
}
div.product-menu div#close-brick {
  width: 14px;
  height: 14px;
  align-self: flex-end;
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 auto;
  margin-bottom: 10px;
  cursor: pointer;
}
div.product-menu div#close-brick img {
  width: 100%;
}
div.product-menu div#close-trim {
  width: 14px;
  height: 14px;
  align-self: flex-end;
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 auto;
  margin-bottom: 10px;
  cursor: pointer;
}
div.product-menu div#close-trim img {
  width: 100%;
}
div.product-menu div#close-roof {
  width: 14px;
  height: 14px;
  align-self: flex-end;
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 auto;
  margin-bottom: 10px;
  cursor: pointer;
}
div.product-menu div#close-roof img {
  width: 100%;
}

.wall-tabs {
  flex: 0 0 auto;
  border-bottom: solid 1px #797979;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.wall-tabs ul {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.wall-tabs ul li {
  flex: 0 0 22.5%;
  height: 40px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: #434343;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 15px;
  margin-right: 10px;
  cursor: pointer;
}
.wall-tabs ul li:last-child {
  margin-right: 0;
}
.wall-tabs ul li.active {
  background-color: #ed1b24;
}

.brick-products {
  display: none;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  min-height: 0;
}
.brick-products.active {
  display: flex;
}

.stone-products {
  display: none;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  min-height: 0;
}
.stone-products.active {
  display: flex;
}

.block-products {
  display: none;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  min-height: 0;
}
.block-products.active {
  display: flex;
}

.mortar-products {
  display: none;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  min-height: 0;
}
.mortar-products.active {
  display: flex;
}
.mortar-products ul {
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  align-content: flex-start;
}
.mortar-products ul li {
  cursor: pointer;
  flex: 0 0 31.5%;
  margin-bottom: 10px;
  margin-right: 5px;
  /*&:nth-child(3n+3) {
            margin-right: 0;
        }*/
}
.mortar-products ul li h1 {
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  padding-top: 5px;
}
.mortar-products ul li .mortar-color {
  width: 100%;
  height: 115px;
  /*background-color: #333333;*/
  position: relative;
}
.mortar-products ul li .mortar-color img {
  width: 100%;
  height: 115px;
  /*          @include breakpoint (phone, tablet, laptop) {
              width: 189px;
            }

            @include breakpoint (phone portrait) {
              width: 100%;
            }

            @include breakpoint (phone landscape) {
              width: 261px;
            }

            @media only screen and (min-width: 376px) and (max-width: 428px) and (orientation: portrait) {
              width: 100%;
            }*/
}
.mortar-products ul li.active .mortar-color::after {
  content: "";
  width: 100%;
  height: 100%;
  border: solid 4px #ed1b24;
  position: absolute;
  top: 0;
  left: 0;
}

.product-list ul.mortar {
  justify-content: space-between;
}
.product-list ul.mortar li.mortar {
  flex: 0 0 48%;
  /*    @include breakpoint (tablet portrait) {
    flex: 0 0 23%;
  }

  @include breakpoint(phone portrait) {
    flex: 0 0 45%;
  }*/
}

.product-filter {
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 10px;
}
.product-filter select {
  flex: 1 1 auto;
  height: 30px;
}
.product-filter select:first-child {
  margin-right: 10px;
}

.product-list {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  min-height: 0;
}
.product-list ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  align-content: flex-start;
}
.product-list ul li {
  cursor: pointer;
  flex: 0 0 48.5%;
  margin-bottom: 10px;
  /*margin: 0px 2px 10px 2px;*/
  /*&:nth-child(odd) {
            margin-right: 10px;
        }*/
}
.product-list ul li img {
  width: 100%;
  position: relative;
}
.product-list ul li h1 {
  font-size: 15px;
  font-weight: 700;
  line-height: 1;
  padding-top: 5px;
}
.product-list ul li p {
  font-size: 15px;
  line-height: 1;
  padding-top: 5px;
}
.product-list ul li .thumb {
  position: relative;
}
.product-list ul li.active .thumb::after {
  content: "";
  width: 100%;
  height: 100%;
  border: solid 4px #ed1b24;
  position: absolute;
  top: 0;
  left: 0;
}
.product-list ul li.hidden {
  display: none;
}

li.mortar.hide {
  display: none;
}

li.mortar-msg {
  display: none;
}
li.mortar-msg.active {
  display: flex;
  flex: 0 0 98%;
  color: #ed1b24;
  text-transform: uppercase;
  cursor: default;
}
li.mortar-msg.active p {
  line-height: inherit;
}
li.mortar-msg.active p span {
  font-weight: 700;
  color: black;
}

div#trim-menu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  height: 100%;
  width: 560px;
  background-color: #ffffff;
  display: flex;
  flex-flow: row nowrap;
  box-shadow: 1px -1px 5px 0px #333333;
  border: solid 1px rgba(51, 51, 51, 0.18);
  transform: translateX(-105%);
  transition: 0.4s transform ease-out;
}
div#trim-menu.active {
  transform: translateX(0);
  transition: 0.4s transform ease-out;
  z-index: 999999;
}

.trim-products {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  min-height: 0;
}
.trim-products ul {
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  align-content: flex-start;
}
.trim-products ul li {
  cursor: pointer;
  flex: 0 0 31.4%;
  margin-bottom: 10px;
  margin-right: 10px;
  min-width: 92px;
}
.trim-products ul li:nth-child(3n+3) {
  margin-right: 0;
}
.trim-products ul li h1 {
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  padding-top: 5px;
}
.trim-products ul li .mortar-color {
  width: 100%;
  height: 115px;
  /*background-color: #333333;*/
  position: relative;
}
.trim-products ul li.active .mortar-color::after {
  content: "";
  width: 100%;
  height: 100%;
  border: solid 4px #ed1b24;
  position: absolute;
  top: 0;
  left: 0;
}

html.ie .trim-products ul li {
  min-width: inherit;
}

div#roof-menu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  height: 100%;
  width: 390px;
  background-color: #ffffff;
  display: flex;
  flex-flow: row nowrap;
  box-shadow: 1px -1px 5px 0px #333333;
  border: solid 1px rgba(51, 51, 51, 0.18);
  transform: translateX(-105%);
  transition: 0.4s transform ease-out;
}
div#roof-menu.active {
  transform: translateX(0);
  transition: 0.4s transform ease-out;
  z-index: 999999;
}

.roof-products {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
}
.roof-products ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.roof-products ul li {
  cursor: pointer;
  flex: 0 0 48.5%;
  margin-bottom: 10px;
}
.roof-products ul li:nth-child(odd) {
  margin-right: 10px;
}
.roof-products ul li img {
  width: 100%;
  position: relative;
}
.roof-products ul li h1 {
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  padding-top: 5px;
}
.roof-products ul li p {
  font-size: 15px;
  line-height: 1;
  padding-top: 5px;
}
.roof-products ul li .thumb {
  position: relative;
}
.roof-products ul li.active .thumb::after {
  content: "";
  width: 100%;
  height: 100%;
  border: solid 4px #ed1b24;
  position: absolute;
  top: 0;
  left: 0;
}

div#calculator-modal.lightbox {
  z-index: 999999;
}
div#calculator-modal.lightbox.active {
  display: block;
}
div#calculator-modal.lightbox .header {
  background-color: #ed1b24;
  padding: 10px;
  margin: 0;
}
div#calculator-modal.lightbox .header h2 {
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
}
div#calculator-modal.lightbox .header p {
  font-size: 14px;
  color: #ffffff;
}
div#calculator-modal.lightbox .header button.close {
  position: relative;
  width: 20px;
  top: auto;
  right: auto;
  transform: none;
  flex: 0 0 auto;
  cursor: pointer;
}
div#calculator-modal.lightbox .content {
  padding: 20px 10px 10px;
  display: flex;
  flex-flow: column nowrap;
}

div#calculator-modal.lightbox > div {
  max-width: 970px !important;
}

.title-main {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}

.calc-title {
  flex: 1 1 auto;
}

.calc-check {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}
.calc-check div {
  margin-left: 10px;
}

/*input[type=checkbox].css-checkbox {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

input[type=checkbox].css-checkbox + label.css-label {
    padding-left: 25px;
    height: 20px;
    display: inline-block;
    line-height: 20px;
    background-repeat: no-repeat;
    background-position: 0 0;
    font-size: 20px;
    vertical-align: middle;
    cursor: pointer;
    color: #ffffff;
    font-size: 14px;
}

input[type=checkbox].css-checkbox:checked + label.css-label {
    background-position: 0 -20px;
}

label.css-label {
    background-image: url(/img/check.png);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}*/
.container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*font-family: 'FreightSansProBook-Regular';*/
  flex: 0 0 13%;
  color: white;
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* Style the checkmark/indicator */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Show the checkmark when checked */
  /* Create the checkmark/indicator (hidden when not checked) */
}
.container input {
  position: absolute;
  opacity: 0;
}
.container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  margin-top: -1px;
}
.container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.container input:checked ~ .checkmark {
  background-color: white;
}
.container .checkmark {
  content: "";
  position: absolute;
  display: block;
}
.container input:checked ~ .checkmark:after {
  display: block;
}
.container .checkmark:after {
  left: 7px;
  top: 2px;
  width: 7px;
  height: 12px;
  border: solid red;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.choose-product {
  flex: 0 0 auto;
}
.choose-product h2 {
  font-size: 20px;
  margin-bottom: 20px;
}
.choose-product ul {
  display: flex;
  flex-flow: row wrap;
}
.choose-product ul li {
  display: flex;
  flex-flow: column nowrap;
  border: solid 2px #A1A1A1;
  position: relative;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  flex: 0 0 18.9%;
  cursor: pointer;
  justify-content: space-around;
}
.choose-product ul li.active {
  border: solid 2px #ed1b24;
}
.choose-product ul li:nth-child(5n+5) {
  margin-right: 0;
}
.choose-product ul li p {
  font-size: 14px;
  margin: 0 !important;
  padding: 0;
}
.choose-product ul li img {
  width: 70%;
  margin: 0 auto;
}
.choose-product ul li .brick-info {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.choose-product ul li .brick-hover {
  cursor: pointer;
}
.choose-product ul li .brick-hover img {
  width: 15px !important;
}
.choose-product ul li .brick-hover:hover .brick-info-hover {
  display: flex;
}
.choose-product ul li .brick-hover:hover img {
  z-index: 9;
  position: relative;
}
.choose-product ul li .brick-info-hover {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  flex-flow: column nowrap;
  padding: 10px;
}
.choose-product ul li .brick-info-hover p {
  font-size: 12px;
  line-height: 1.5;
}

.choose-size {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.size-side {
  flex: 0 0 65.1%;
  padding: 10px;
}
.size-side h2 {
  font-size: 20px;
  margin-bottom: 20px;
}
.size-side .width-height {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
.size-side .width-height .width-size {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-right: 50px;
}
.size-side .width-height .height-size {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.size-side .width-height h4 {
  margin-bottom: 10px;
  font-size: 20px;
}
.size-side .width-height input {
  width: 100px;
  height: 30px;
}
.size-side .area-size {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.size-side .area-size h4 {
  margin-bottom: 10px;
  font-size: 20px;
}
.size-side .area-size input {
  width: 150px;
  height: 30px;
}
.size-side .or {
  background-color: #434343;
  color: #ffffff;
  width: 150px;
  height: 40px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 20px auto;
}
.size-side .or p {
  margin: 0 !important;
}
.size-side button {
  background-color: #ed1b24;
  color: #ffffff;
  width: 100%;
  height: 50px;
  margin-top: 20px;
}

.total-side {
  flex: 0 0 31%;
  border: solid 1px #A1A1A1;
  padding: 10px;
  display: flex;
  flex-flow: column nowrap;
}
.total-side .total-bricks {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  max-width: 270px;
}
.total-side .total-bricks h1 {
  font-size: 48px;
  color: #ed1b24;
  font-weight: 600;
  word-break: break-all;
}
.total-side .total-bricks p {
  font-size: 23px;
  color: #ed1b24;
  font-weight: 600;
}
.total-side .total-bricks h4 {
  font-size: 28px;
}
.total-side p {
  font-size: 13px;
  flex: 0 0 auto;
  margin: 0 !important;
}

.noscroll.active {
  overflow: hidden;
}

.right-foot {
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 90%;
}

.disclaimer-btn {
  display: none;
}

.disclaimer {
  margin: 0 10px;
  flex: 0 0 75%;
}
.disclaimer p {
  font-size: 0.67em;
  color: #797979;
  line-height: 1.4;
}

.close-disclaimer {
  display: none;
}

.disclaimer-mobile {
  margin: 0 10px 0 0;
  flex: 0 0 auto;
  display: none;
}
.disclaimer-mobile p {
  font-size: 12px;
  color: #797979;
  line-height: 1.4;
  font-size: 0.67em;
  padding: 10px 0 5px;
}

@media only screen and (min-width: 1441px) {
  #visualizer {
    position: absolute;
    height: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .container {
    flex: 0 0 28%;
  }
  .disclaimer {
    width: 90% !important;
    height: 90%;
    position: fixed !important;
  }
  .disclaimer p {
    font-size: 14px;
    color: #454545;
    line-height: 1.4;
    padding: 20px;
  }
}
@media only screen and (max-width: 767px) and (orientation: portrait) {
  .disclaimer-btn {
    order: 2;
    /*margin-right: 0 !important;
        margin-left: 0 !important;*/
    margin-left: 30px;
  }
  .footer div#print i {
    font-size: 16px;
    margin-right: 10px;
    color: #ffffff;
  }
  .footer div#print {
    height: 40px;
  }
  body, html, #MAIN {
    height: auto !important;
  }
  html {
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
  header .visualizer-wrapper {
    flex-flow: column nowrap;
  }
  nav.visualizer {
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
  }
  div#calc-button {
    order: 2;
    width: 100%;
    justify-content: center;
    flex: 0 0 auto;
  }
  header nav.visualizer div {
    flex: 0 0 48.8%;
    height: 40px;
    border: solid 1px #777777;
    margin: 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  .surface-menu {
    position: relative;
    order: -1;
    top: auto;
    right: auto;
    margin-bottom: 10px;
  }
  .surface-menu ul {
    justify-content: space-between;
  }
  .surface-menu ul li {
    width: auto;
    flex: 0 0 31.7%;
    margin: 0;
    font-size: 13px;
    line-height: 1px;
    height: 60px;
    padding: 0 10px;
    justify-content: center;
    align-items: center;
  }
  header {
    margin-bottom: 10px;
  }
  .footer {
    order: -1;
    margin: 0 0 10px 0;
    flex-flow: row wrap;
  }
  div#print {
    order: 2;
  }
  li#brick-toggle {
    line-height: 17px;
    text-align: center;
  }
  .viz-holder {
    flex: 0 0 auto;
    margin-bottom: 10px;
  }
  div#close-brick, div#close-trim, div#close-roof {
    display: none !important;
  }
  div#brick-menu {
    position: relative;
    box-shadow: none;
    flex-flow: column nowrap;
    width: 100%;
    transform: none;
    display: none;
  }
  div#brick-menu.active {
    transform: none;
    display: flex;
  }
  .side-surface {
    padding: 10px;
  }
  .side-surface ul {
    flex-flow: row wrap;
  }
  .side-surface ul li {
    margin-right: 10px;
    width: auto;
    flex: 0 0 48.3%;
  }
  .side-surface ul li:nth-child(even) {
    margin-right: 0;
  }
  .wall-tabs ul li {
    height: 50px;
  }
  .product-list ul li {
    flex: 0 0 48.3%;
  }
  .mortar-products ul li {
    flex: 0 0 48.4%;
  }
  /*.mortar-products ul li:nth-child(3n+3) {
        margin-right: 10px;
    }

    .mortar-products ul li:nth-child(2n+2) {
        margin-right: 0;
    }*/
  .mortar-products ul li .mortar-color {
    height: 115px;
  }
  div#trim-menu {
    position: relative;
    box-shadow: none;
    flex-flow: column nowrap;
    width: 100%;
    transform: none;
    display: none;
  }
  div#trim-menu.active {
    transform: none;
    display: flex;
  }
  .trim-products ul li {
    flex: 0 0 48.4%;
  }
  .trim-products ul li:nth-child(3n+3) {
    margin-right: 10px;
  }
  .trim-products ul li:nth-child(2n+2) {
    margin-right: 0;
  }
  .trim-products ul li .mortar-color {
    height: 115px;
  }
  div#roof-menu {
    position: relative;
    box-shadow: none;
    flex-flow: column nowrap;
    width: 100%;
    transform: none;
    display: none;
  }
  div#roof-menu.active {
    transform: none;
    display: flex;
  }
  .calc-check {
    margin-top: 10px;
  }
  .choose-product ul li:nth-child(5n+5) {
    margin-right: 10px;
  }
  .choose-product ul li:nth-child(even) {
    margin-right: 0;
  }
  .choose-product ul li {
    flex: 0 0 48.4%;
  }
  .choose-size {
    flex-flow: column nowrap;
  }
  .size-side {
    flex: 0 0 auto;
  }
  .total-side {
    flex: 0 0 auto;
    margin-top: 10px;
  }
  .total-bricks {
    margin-bottom: 20px;
  }
  .product-filter {
    flex-flow: row wrap;
  }
  .product-filter select {
    margin: 2px;
  }
  .product-filter select:first-child {
    margin-right: 2px;
  }
}
@media only screen and (max-width: 767px) and (orientation: landscape) {
  body, html, #MAIN {
    height: auto;
  }
  html {
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
  header nav.visualizer div {
    font-size: 14px;
    margin-right: 20px;
  }
  div#brick-menu {
    position: fixed;
    width: 100%;
  }
  div#trim-menu {
    position: fixed;
    width: 100%;
  }
  div#roof-menu {
    position: fixed;
    width: 100%;
  }
  .wall-tabs ul li {
    flex: 0 0 24%;
  }
  .product-list ul {
    justify-content: flex-start;
  }
  .product-list ul li {
    flex: 0 0 47.5%;
    margin-right: 10px;
  }
  .product-list ul li:nth-child(odd) {
    margin-right: 10px;
  }
  .product-list ul li:nth-child(3n+3) {
    /*margin-right: 0 !important;*/
  }
  .mortar-products ul li {
    flex: 0 0 23.3%;
    /*&:nth-child(3n+3) {
            margin-right: 10px;
        }

        &:nth-child(4n+4) {
            margin-right: 0;
        }*/
  }
  .trim-products ul li {
    flex: 0 0 23.2%;
    /*&:nth-child(4n+4) {
            margin-right: 0 !important;
        }*/
  }
  .trim-products ul li:nth-child(3n+3) {
    margin-right: 10px;
  }
  .trim-products ul li .mortar-color {
    width: 100%;
    height: 110px;
  }
  #roof-menu .product-list ul li {
    flex: 0 0 23.7%;
    min-width: 115px;
    /*&:nth-child(4n+4) {
              margin-right: 0 !important;
          }*/
  }
  #roof-menu .product-list ul li:nth-child(3n+3) {
    margin-right: 10px !important;
  }
  .calc-check {
    margin-top: 10px;
  }
  .choose-product ul li:nth-child(5n+5) {
    margin-right: 10px;
  }
  .choose-product ul li:nth-child(3n+3) {
    margin-right: 0;
  }
  .choose-product ul li {
    flex: 0 0 32.2%;
  }
  div#calculator-modal.lightbox {
    position: fixed;
  }
}
@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 1024px) {
  .choose-product ul li .brick-hover img {
    display: none;
  }
  .brick-info {
    flex-flow: column nowrap !important;
    align-items: flex-start !important;
  }
  .brick-info-hover {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    flex: 0 0 auto;
    display: flex !important;
    padding: 0 !important;
  }
  .brick-info-hover p {
    font-weight: 400 !important;
  }
  .choose-product ul li p {
    font-weight: 700;
  }
  .right-foot {
    -ms-flex: 0 0 70%;
    flex: 0 0 70%;
  }
  .footer div#print {
    -ms-flex: 0 0 15%;
    flex: 0 0 15%;
  }
  /*.disclaimer-btn {
    display: block;
    margin-right: 20px;
    margin-left: 70px;
    cursor: pointer;

    p {
      font-size: 12px;
      color: #797979;
      line-height: 1.4;
      text-decoration: underline;
    }
  }*/
  .disclaimer {
    display: none;
  }
  .disclaimer-mobile {
    display: flex;
  }
  .disclaimer p {
    display: none;
  }
  .right-foot {
    display: flex;
    flex-flow: row nowrap;
    flex: 0 0 70%;
  }
  .right-foot {
    align-items: center;
  }
  .close-disclaimer {
    display: flex;
    width: 20px;
    flex-flow: column nowrap;
    align-self: flex-end;
    cursor: pointer;
    margin: 20px;
  }
  #disclaimer-modal.lightbox.active {
    display: flex;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .viz-holder {
    flex: 0 0 auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .disclaimer-btn {
    order: 2;
    margin-right: 0 !important;
    margin-left: 10px !important;
  }
  .disclaimer {
    top: 19%;
    width: 100%;
  }
  body, html, #MAIN {
    height: auto;
  }
  html {
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
  .footer {
    order: -1;
    margin: 0 0 10px 0;
  }
  div#print {
    order: 2;
  }
  .surface-menu {
    position: relative;
    top: auto;
    right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .surface-menu ul li {
    width: auto;
    flex: 0 0 32.4%;
  }
  div#close-brick, div#close-trim, div#close-roof {
    display: none !important;
  }
  .side-surface {
    padding: 10px;
  }
  div#brick-menu {
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    box-shadow: none;
    display: none;
  }
  div#brick-menu.active {
    transform: none;
    display: flex;
  }
  .product-list ul {
    overflow: visible;
  }
  .wall-tabs ul li {
    flex: 0 0 32%;
  }
  div#trim-menu {
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    box-shadow: none;
    display: none;
  }
  div#trim-menu.active {
    transform: none;
    display: flex;
  }
  div#roof-menu {
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    box-shadow: none;
    display: none;
  }
  div#roof-menu.active {
    transform: none;
    display: flex;
  }
  .wall-tabs ul li {
    flex: 0 0 23%;
  }
  .product-list ul {
    justify-content: flex-start;
  }
  .product-list ul li {
    /*&:nth-child(3n+3) {
              margin-right: 0 !important;
          }*/
  }
  .product-list ul li:nth-child(odd) {
    margin-right: 10px;
  }
  .mortar-products ul li {
    flex: 0 0 23.6%;
    /*&:nth-child(4n+4) {
            margin-right: 0;
        }*/
  }
  .mortar-products ul li:nth-child(3n+3) {
    margin-right: 10px;
  }
  .trim-products ul li {
    flex: 0 0 23.6%;
    /*&:nth-child(4n+4) {
            margin-right: 0 !important;
        }*/
  }
  .trim-products ul li:nth-child(3n+3) {
    margin-right: 10px;
  }
  .trim-products ul li .mortar-color {
    width: 100%;
    height: 126px;
  }
  #roof-menu .product-list ul li {
    flex: 0 0 23.9%;
    /*&:nth-child(4n+4) {
              margin-right: 0 !important;
          }*/
  }
  #roof-menu .product-list ul li:nth-child(3n+3) {
    margin-right: 10px !important;
  }
  .calc-check {
    margin-top: 10px;
  }
  .choose-product ul li:nth-child(5n+5) {
    margin-right: 10px;
  }
  .choose-product ul li:nth-child(4n+4) {
    margin-right: 0;
  }
  .choose-product ul li {
    flex: 0 0 23.9%;
  }
  section.visualizer {
    flex: 0 0 auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  div.visualizer-pane {
    flex: 0 0 auto;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  div.visualizer-pane {
    flex: 0 0 auto;
  }
  body, html, #MAIN {
    width: 100%;
    height: auto;
  }
}
/* ----------------------------------------------------------------
My Projects
-----------------------------------------------------------------*/
#my-projects > div {
  width: 100% !important;
  margin: 2% 0;
  max-width: 600px;
}

#my-projects .innerFormWrapper {
  box-sizing: border-box;
}

#my-projects .formTitle h2,
#my-projects h2.forgotTitle,
#my-projects h2.forgotTitle label,
#my-projects h2.saveTitle,
#my-projects h2.saveTitle label {
  margin-bottom: 1.5vmin;
  padding: 0;
  color: #434343;
  font-size: 2.2vmin;
  font-weight: bold;
}

#my-projects h2.forgotTitle span {
  color: #5A5A5A;
  font-size: 0.7em;
  font-weight: normal;
  display: block;
}

#my-projects .formBTNS ul {
  margin: 2vmin 0 0;
  padding: 0;
  list-style: none;
}

#my-projects .linkBTNS {
  color: #5A5A5A;
  display: inline-block;
  cursor: pointer;
}

#my-projects .linkBTNS:hover {
  text-decoration: underline;
}

#my-projects .loginBTN,
#my-projects .forgotBTN,
#my-projects .regBTN {
  margin-bottom: 1.5vmin;
}

#my-projects .errors {
  margin-bottom: 2vmin;
}

#my-projects .TitleBar {
  display: flex;
  flex-wrap: wrap;
}

#my-projects .TitleBar h2 {
  flex: 2;
}

#my-projects .titleBarBTNS {
  display: flex;
  align-items: center;
}

#my-projects .titleBarBTNS .buttons {
  margin-right: 1vmin;
  font-size: 0.9em;
  cursor: pointer;
}

#my-projects .titleBarBTNS .buttons:last-child {
  margin-right: 0;
}

#my-projects .closeBTN {
  margin: 0 !important;
  color: #434343;
  font-size: 0 !important;
  font-weight: 400;
  line-height: 1;
  position: relative;
  cursor: pointer;
}

#my-projects .closeBTN:before {
  content: "X";
  margin-left: 1vmin;
  font-size: 3vh;
  cursor: pointer;
}

#my-projects .myProjectBTNsUL {
  margin: 0;
  padding: 0;
  list-style: none;
}

#my-projects .myProjectBTNsUL li {
  margin-bottom: 4px;
}

#my-projects .projectsForm .projectWrapper {
  width: 100%;
  margin-bottom: 3vmin;
  display: table;
}

li.Edit.prj-viz {
  padding: 10px;
  color: white;
  text-transform: uppercase;
  background-color: #808080;
  border: none;
  display: inline-block;
  letter-spacing: 1px;
  cursor: pointer;
}

#my-projects .projectsForm > img {
  display: table-cell;
  vertical-align: top;
}

#my-projects .projectsForm .innerWrapper {
  width: 100%;
  padding-left: 4vmin;
  display: table-cell;
  vertical-align: top;
}

#my-projects .projectsForm .projectTitle {
  margin-bottom: 4px;
  padding-left: 0;
  line-height: 1;
}

#my-projects .projectsForm .projectDate {
  margin-bottom: 1vmin;
  font-size: 0.8em;
}

#uploadOverlay, #modalOverlay, #successOverlay, #congratsOverlay, #confirmOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.78);
  display: none;
  z-index: 3010;
}

/*----------------------------------------------------------------
Lightbox
-----------------------------------------------------------------*/
.lightbox {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  z-index: 99999;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

#calculator-modal.lightbox {
  position: fixed;
}

.lightbox > div {
  width: 100%;
  max-width: 800px;
  background-color: white;
}

.lightbox button:focus {
  outline: none;
}

.lightbox .header, #my-projects .TitleBar {
  padding: 10px 15px;
  margin: 10px;
  color: #434343;
  background-color: #ededed;
  position: relative;
}

.lightbox .header h2, #my-projects .TitleBar h2 {
  padding: 0;
  color: #434343;
}

.lightbox .header .close {
  padding: 0;
  background-color: transparent;
  border: none;
  font-weight: bold;
  position: absolute;
  top: 50%;
  right: 1.5vmin;
  transform: translateY(-50%);
}

.lightbox .content, #my-projects .Content {
  padding: 0 20px 20px;
}

.lightbox .content p {
  margin: 0 0 2vmin;
}

.lightbox .content label, #my-projects .Content label {
  color: #73706d;
  display: block;
}

.lightbox .content label ~ label, #my-projects .Content .lbl ~ .lbl {
  margin-top: 0.8vmin;
}

.lightbox .content input, .lightbox .content textarea, #my-projects .Content input {
  padding: 0.5vmin 0.6vmin;
  border: 1px solid #c2c1c1;
  display: block;
}

.lightbox .content textarea {
  width: 100%;
  min-height: 100px;
}

.lightbox .content .buttons {
  margin-top: 2vmin;
}

.lightbox .content button, #my-projects .loginBTN, #my-projects .forgotBTN, #my-projects .regBTN, #my-projects .changePwdBTN, #my-projects .saveBTN, #my-projects .Use, #my-projects .Remove, #myProjectBTNS .Edit {
  padding: 10px;
  color: white;
  text-transform: uppercase;
  background-color: #ed1b24;
  border: none;
  display: inline-block;
  letter-spacing: 1px;
  cursor: pointer;
}

.lightbox .validation-error, #my-projects .errors {
  margin-top: 0.3vmin;
  color: #ed1b24;
  font-size: 0.9em;
  letter-spacing: 1px;
}

#email-modal .required-notice {
  margin: 1vmin 0;
  font-size: 0.8em;
  text-align: right;
}

#email-modal input[type=text], #my-projects .Content input[type=text], #my-projects .Content input[type=password] {
  width: 100%;
  max-width: 250px;
}

#my-projects .Remove {
  background-color: #434343;
}

/* ----------------------------------------------------------------
Loading Graphic
-----------------------------------------------------------------*/
.loading-msg {
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  text-align: center;
  transform: translate(-50%, -50%);
}

.loading-msg img {
  width: auto;
}

@keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0;
  }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1;
  }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0;
  }
}
.uil-ripple-css {
  background: none;
  position: relative;
  width: 200px;
  height: 200px;
}

.uil-ripple-css div {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  width: 0;
  height: 0;
  opacity: 0;
  border-radius: 50%;
  border-width: 12px;
  border-style: solid;
  animation: uil-ripple 2s ease-out infinite;
}

.uil-ripple-css div:nth-of-type(1) {
  border-color: #EC2129;
}

.uil-ripple-css div:nth-of-type(2) {
  border-color: #EC2129;
  animation-delay: 1s;
}

/*----------------------------------------------------------------
Modals
-----------------------------------------------------------------*/
.modal-show {
  visibility: visible !important;
  opacity: 1 !important;
}

#MODALS {
  visibility: hidden;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  opacity: 0;
  z-index: 998;
  transition: all 0.5s;
}

#MODALS div.top-bar {
  width: 100%;
  height: 6%;
  background-color: rgb(35, 93, 123);
}

#MODALS div.close {
  position: absolute;
  right: 1%;
  top: 1.4%;
  width: 1.5vh;
  height: 1.5vh;
  background: url(/img/close.svg);
  cursor: pointer;
}

/*----------------------------------------------------------------
Message Overlays
-----------------------------------------------------------------*/
.message-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(35, 93, 123, 0.95);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  display: none;
}

.message-overlay img {
  width: 100%;
  max-width: 600px;
  max-height: 100%;
  display: inline-block;
  vertical-align: middle;
}

ul#product-accordion {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

ul#product-accordion li {
  font-size: 3vmin;
  background-color: lightgray;
  padding: 2% 5%;
  margin-bottom: 1%;
}

ul#product-accordion li:last-child() {
  margin-bottom: 0;
}

div.social {
  width: 100%;
  height: 10.4%;
  display: flex;
  justify-content: space-between;
  font-size: 2vh;
  line-height: 4.5;
  text-transform: uppercase;
}

div.feedback {
  cursor: pointer;
}

div.share {
  height: 100%;
  width: 50%;
  display: flex;
}

div.share span {
  display: inline-block;
  text-align: right;
  padding: 0 4% 0 0;
  width: 50%;
}

#share {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
}

#share a, #share div {
  display: inline-block;
  border-radius: 100%;
  width: 3.5vh;
  height: 3.5vh;
  margin: 0 1%;
  text-align: center;
  cursor: pointer;
}

#share i {
  vertical-align: 148%;
  cursor: pointer !important;
}

/* ==========================================================================
   TOUR
   ========================================================================== */
#tour {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  z-index: 5000;
}

.tour-step {
  width: 100%;
  max-width: 340px;
  padding: 10px;
  display: none;
  z-index: 5001;
}
.tour-step .main {
  background-color: white;
  border: 1px solid #E0E0E0;
  border-bottom: none;
  position: relative;
}
.tour-step p {
  margin: 0;
  padding: 3.5vmin 2vmin 4.5vmin;
  font-size: 1.2em;
  font-family: "ProximaNova-Regular";
}

.tour-step .header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.tour-step .header h3 {
  margin: 0;
  padding: 2vmin 0 0 2vmin;
  font-size: 3vmin;
  font-family: "ProximaNova-Semibold";
  font-weight: normal;
}
.tour-step .header .close {
  margin: 4px 4px 0 0;
  padding: 0.5vmin 1vmin;
  color: white;
  background-color: #2A4560;
  font-size: 1.8vmin;
  text-align: center;
  cursor: pointer;
}

.tour-step .footer {
  width: 100%;
  color: white;
  font-size: 1.2em;
  display: flex;
  position: relative;
}
.tour-step .footer div {
  padding: 1.4vmin;
  background-color: #3D3E39;
}
.tour-step .footer button {
  padding: 1.4vmin;
  background-color: #2a4560;
  border: 0;
  flex-grow: 1;
  border-radius: 0;
  color: #fff;
  font-family: "ProximaNova-Regular";
  cursor: pointer;
}
.tour-step .footer button:focus {
  outline: none;
}

.tour-step.arrow-right .main:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 26px solid transparent;
  border-bottom: 26px solid transparent;
  border-left: 21px solid #E0E0E0;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
}

.tour-step.arrow-right .main:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 20px solid white;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
}

.tour-step.arrow-up .main:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 26px solid transparent;
  border-right: 26px solid transparent;
  border-bottom: 21px solid #E0E0E0;
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);
}

.tour-step.arrow-up .main:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 20px solid white;
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);
}

.tour-step.arrow-down .footer:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-top: 20px solid #2a4560;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.tour-step.arrow-left .main:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 26px solid transparent;
  border-bottom: 26px solid transparent;
  border-right: 21px solid #E0E0E0;
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
}

.tour-step.arrow-left .main:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-right: 20px solid white;
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
}

#applied-products {
  min-width: 25%;
  height: 82.1%;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3000;
  transform: translateX(100%) translateX(33px);
  transition: 400ms all ease-out;
}

#applied-products.open {
  transform: translateX(0);
}

#applied-products .close {
  padding: 6px 10px;
  color: white;
  border: none;
  background-color: rgb(35, 93, 123);
  font-size: 1.2em;
  position: absolute;
  top: 0;
  right: 100%;
}

#applied-products .close:focus {
  outline: none;
}

#applied-list {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 1.7vmin 2.2vmin;
  color: white;
  list-style: none;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

#applied-list li {
  padding: 8% 0;
  border-bottom: 3px solid #D4D2D3;
  display: flex;
}

#applied-list li:first-child {
  padding-top: 0;
}

#applied-list li > div {
  margin-left: 5%;
  font-size: 1.7vmin;
  display: inline-block;
  vertical-align: top;
}

#applied-list li img {
  width: 100px;
  display: inline-block;
  vertical-align: top;
  flex-basis: 100px;
}

#applied-list li .surface-name {
  font-weight: bold;
}

/* ==========================================================================
    VARIABLES
   ========================================================================== */
/* ==========================================================================
    IM
   ========================================================================== */
.modal-headline {
  text-align: left;
  flex: 0 0 auto;
  color: #434343;
  font-size: 24px;
  line-height: 36px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}

.modal-close {
  float: right;
  color: #000000;
  margin-right: 0px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 18px;
  width: 25px;
}

.modal-close:hover {
  color: #333333 !important;
}

.modal-box {
  width: 100%;
  height: auto;
  margin: 2vmin auto;
  text-align: center;
  flex: 1 0 auto;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  max-height: 460px;
}

.upload-box {
  width: 100%;
  height: auto;
  margin: 0;
  background-color: #f3f3f3;
  border: dashed 2px #B3B2B2;
  color: #65656a;
  left: 0;
  right: 0;
  z-index: 300;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  position: absolute;
  height: 100%;
}

.upload-box p {
  font-size: 14px;
  color: #434343;
  position: absolute;
  top: 61%;
  margin: 0 auto;
  width: 100%;
  left: 0;
}

.upload-box input[type=file] {
  display: none;
}

.modal-pic {
  background-color: #EAEAEA;
  border-radius: 3px;
  z-index: 200;
  width: 100%;
  height: auto;
  flex: 0 1 auto;
  display: flex;
  flex-flow: column nowrap;
  overflow: auto !important;
  height: 600px;
}

.trash {
  background-color: #434343;
  color: #FFF;
  font-size: 4vmin;
  cursor: pointer;
  border-top-right-radius: 3px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 200;
  padding: 1vmin;
  font-size: 36px;
}

.modal-pic #imgToUpload {
  width: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  flex: 1 0 auto;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .modal-pic #imgToUpload {
    width: auto;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    flex: 1 0 auto;
  }
}
.modal-label {
  float: left;
  font-size: 16px;
  font-weight: bold;
  color: #65656a;
  margin: 5px 0 0 55px;
}

.modal-input {
  padding: 10px;
  border: 1px solid #B1B1B1;
  border-radius: 5px;
  border-image: none;
  font-size: 20px;
  color: #65656a;
  box-shadow: 0px 0px 0px 0px rgba(42, 42, 42, 0.75);
  margin: 10px 0 0 50px;
  width: 440px;
  float: left;
}

.project-button {
  -moz-border-radius: 5px;
  display: block;
  text-align: center;
  font-size: 20px;
}

.project-button i {
  margin-left: 10px;
}

.uploaderBTN {
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  border-radius: 1px;
  box-sizing: border-box;
  padding: 15px;
  background-color: transparent;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-size: 14px;
  border: solid 1px #454545;
  background-color: #454545;
}

/*.uploaderBTN:hover {
    background: $blue;
    color: $white;
}*/
div#upload-inner {
  width: 100%;
  max-width: 1155px;
  padding: 10px 10px 30px;
  background-color: #ffffff;
  position: relative;
  display: inline-flex;
  flex-flow: column nowrap;
  background-color: #ffffff;
}

div#upload-main {
  display: flex;
  flex-flow: row nowrap;
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  z-index: 997;
}

#upload-container {
  padding: 10px;
}

div#close-upload {
  width: 25px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 10px;
  top: 10px;
}

#closemodal {
  cursor: pointer;
  font-size: 12px;
  color: #353334;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
}

#closemodal img {
  width: 100%;
  height: 25px;
}

#closemodal i {
  color: #ffffff !important;
  font-size: 18px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.upload-left-head {
  font-size: 24px;
  color: #434343;
  font-weight: 700;
  line-height: 36px;
  margin-top: 20px;
  font-style: normal;
  text-transform: uppercase;
}

.upload-box-left {
  width: 36%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding-left: 20px;
}

.upload-box-right {
  width: 60%;
  margin-left: 30px;
  display: flex;
  flex-flow: column nowrap;
  padding-top: 93px;
}

div#good-bad-imgs {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-right: 2%;
}

.upload-left-text {
  font-size: 14px;
  color: #434343;
}

.upload-left-text p {
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
}

.upload-list i {
  color: #EC2129;
  margin-left: 5px;
  margin-right: 5px;
}

.upload-list {
  font-size: 12px;
  color: #434343;
}

.upload-button-box {
  width: 100%;
  text-align: center;
}

.photo-good {
  width: 48%;
  position: relative;
  box-sizing: border-box;
  background-repeat: no-repeat;
}

.photo-good img {
  width: 100%;
}

.photo-text {
  width: 100%;
  margin-top: 2%;
  float: left;
}

.star {
  color: #e11c00;
  float: left;
  width: 10px;
  margin-right: 10px;
}

.star-text {
  float: left;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  color: #434343;
}

.star-text p {
  margin: 0;
}

.star-text i {
  color: #EC2129;
  margin-right: 1%;
}

.photo-box-bar {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.27);
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  box-sizing: border-box;
  padding: 4%;
  position: absolute;
  z-index: 5000;
  text-align: center;
  bottom: 0;
}

.btn-upload {
  color: #ffffff;
  font-size: 19px;
  padding: 13px 15px;
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: solid 2px #ffffff;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background: #EC2129;
  text-align: left;
  text-transform: uppercase;
  font-weight: 400;
  border: 1px solid #EC2129;
  transition: 50ms all ease-out;
}

/*.btn-upload:hover {
    background-color:$blue;
    color:$white;
    transition: 50ms all ease-in;
}*/
.btn-upload.browse {
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  flex: 0 0 auto;
}

.angle-right {
  height: 20px;
  position: absolute;
  right: 9px;
  top: 25%;
  width: 23px;
}

.angle-right i {
  color: #fff;
}

.angle-right img {
  height: 100%;
}

.times {
  position: absolute;
  right: 6px;
  top: 6px;
  width: 26px;
}

.times img {
  height: 100%;
  width: 100%;
}

div#upload-section {
  display: flex;
  flex-flow: column nowrap;
  height: auto;
  flex: 1 0 auto;
  padding: 25px;
}

div#process-upload > div {
  width: 100%;
  max-width: 900px;
  flex-flow: column nowrap;
  position: relative;
}

div.modal {
  display: none;
}

div.modal.active {
  display: flex;
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) and (orientation: landscape) {
  .modal-box {
    max-height: 422px;
  }
}
@media only screen and (max-width: 315px) {
  html.ie11 .photo-text {
    margin-top: -37%;
  }
}
@media only screen and (max-width: 767px) and (orientation: portrait) {
  div#process-upload > div {
    max-width: 360px;
  }
  .modal-headline {
    font-size: 26px;
  }
  html.ie11 .modal-headline {
    transform: none;
  }
  div#upload-main {
    flex-flow: column nowrap;
  }
  .upload-box-left {
    width: 100%;
    margin-bottom: 30px;
    padding-left: 0;
  }
  .upload-box-right {
    width: 100%;
    margin: 0;
    padding-top: 0;
  }
  .upload-left-head {
    font-size: 23px;
    margin-bottom: 10px;
  }
  .upload-button-box {
    margin-top: 10px;
  }
  .photo-box-bar {
    font-size: 15px;
  }
  html.ie11 .photo-box-bar {
    position: relative;
  }
  .modal-box {
    max-height: 250px;
  }
  .btnim-purple {
    font-size: 2vmin !important;
  }
  .crop-box h2 {
    font-size: 18px !important;
  }
  .btnim {
    font-size: 12px !important;
  }
  .star-text {
    font-size: 12px;
  }
  .btn-upload.browse {
    width: 100%;
  }
  html.ie11 .modal-box, html.ie11 .btn-upload.browse {
    transform: none;
  }
  html.ie11 .btn-upload.browse {
    transform: none;
  }
  /*html.ie11 .times {
      top: -29px;
      right: -18px;
  }*/
  div#upload-link div p {
    font-size: 12px;
  }
  div#upload-link div h4 {
    font-size: 14px;
    margin-bottom: 5px;
  }
}
@media only screen and (max-width: 767px) and (orientation: landscape) {
  div#process-upload > div {
    width: 600px;
  }
  div#close-upload {
    margin-bottom: 20px;
  }
  .upload-box-left {
    width: 51%;
  }
  .upload-box-right {
    width: 50%;
  }
  .upload-left-head {
    font-size: 24px;
  }
  .btn-upload {
    padding: 10px;
  }
  .star-text {
    font-size: 12px;
  }
  .upload-left-text p {
    padding-top: 8%;
  }
  .photo-box-bar {
    font-size: 15px;
  }
  .modal {
    top: 0px;
  }
  .btn-upload.browse {
    width: 100%;
  }
  .modal-box {
    max-height: 250px;
  }
  html.ie11 .modal-box, html.ie11 .btn-upload.browse {
    transform: none;
  }
  html.ie11 .btn-upload.browse {
    transform: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .modal {
    top: 30px;
  }
  .upload-left-head {
    font-size: 24px;
    line-height: 30px;
  }
  div#process-upload > div {
    width: 700px;
  }
  .modal-box {
    max-height: 450px;
  }
  /*html.ie11 .modal-box {
      transform: translate(5%, 2%);
  }*/
  .btn-upload.browse {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  div#process-upload > div {
    width: 730px;
  }
  .upload-box-left {
    width: 40%;
  }
  .upload-box-right {
    width: 55%;
  }
  .upload-left-head {
    font-size: 20px;
    line-height: 26px;
    margin-top: 10px;
  }
  .btn-upload.browse {
    width: 100%;
  }
  .upload-left-text p {
    margin-bottom: 0;
  }
}
/* ==========================================================================
   VARIABLES
  ========================================================================== */
body.page-im {
  overflow-y: auto;
  overflow-x: hidden;
  width: 99.5%;
}

#im-container {
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  background-color: #ffffff;
  display: flex;
}

#im-box {
  width: 75%;
  height: 100%;
  position: static;
  z-index: 60;
  background-color: #fff;
  right: 0;
  overflow-y: auto;
  max-height: 900px;
  overflow-x: hidden;
}

#im-view {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  box-sizing: border-box;
}

.im-view-top {
  width: 100%;
  padding-top: 0px;
  background-color: #fff;
}

.ctr {
  width: auto;
  text-align: center;
  margin-bottom: 0.5%;
  margin-top: 0.5%;
  padding-top: 20px;
}

.im-overlay {
  position: absolute;
  left: 0px;
  z-index: 500;
  position: relative;
}

#cropPhoto {
  width: 100%;
}

#map-box {
  width: 100%;
  display: none;
  z-index: 10;
}

#map-view {
  width: 626px;
  float: right;
}

.map-view-top {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 0.5%;
  margin-top: 0.5%;
  background-color: #ffffff;
}

.ctr-map {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.btnim.active {
  color: #ffffff;
  cursor: pointer;
  text-decoration: none !important;
}

#viz {
  height: auto !important;
}

.ImageMapper {
  height: auto !important;
  position: static !important;
}
.ImageMapper > canvas:nth-child(2) {
  position: static !important;
}

.btnim {
  text-align: center;
  -moz-border-radius: 5px;
  box-sizing: border-box;
  display: inline-block;
  width: auto;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  text-transform: uppercase;
  padding: 10px 30px;
  background-color: #434343;
  border: solid 1px #434343;
  margin: 1px;
}

#zoom-controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

#panning-area {
  margin-top: 10px;
  display: flex;
  align-items: center;
  visibility: hidden;
  -webkit-user-select: none;
  display: none;
}

.btnim.btnpan {
  margin: 0 2px;
  padding: 6px 10px;
}

.btnim.mod {
  width: 75%;
  margin: 2%;
}

.cancel.viz-button.span2.btnim.mod.active.hvr-hollow {
  display: none;
}

.mapping-box {
  width: 588px;
  border: solid 2px #fff;
  background-color: #fff;
  margin: 0 auto;
}

.mapping-nav {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  margin-top: 2px;
  justify-content: space-around;
}

.mapping-nav-left {
  padding-left: 3px;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mapping-nav-right {
  margin-top: 8px;
  padding: 4px 0;
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 auto;
}

#im-tools {
  width: 35%;
  background-color: #ffffff;
  position: relative;
  flex: 0 0 auto;
  z-index: 551;
}
#im-tools > div {
  padding-bottom: 10px;
  background-color: #ffffff;
}

#im-crop {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  left: 0;
  right: 0;
  top: 0;
  z-index: 22230;
  /*box-shadow: 5px 0px 10px -5px #585858;*/
}

/*VIDEO HELP DIV - WILL SHOW LATER */
.crop-fullscreen {
  width: 100%;
  height: auto;
  text-align: center;
  color: #FFF;
  text-decoration: underline;
  cursor: pointer;
  text-decoration: none;
  background-repeat: no-repeat;
  margin: 0 auto;
}

.crop-fullscreen img {
  width: 70%;
  height: 100%;
}

.crop-caption {
  background-color: #65656a;
  font-size: 1.5vh;
  padding: 3% 2% 2%;
  box-sizing: border-box;
  margin-top: -2%;
}

.crop-box {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
}

.crop-box h2 {
  font-size: 28px;
  text-align: center;
  font-weight: 700;
  color: #434343;
  margin-bottom: 20px;
  padding: 0;
  margin-top: 10px;
  font-style: normal;
  text-transform: uppercase;
}

.crop-box p {
  text-align: center;
  font-size: 14px;
  color: #434343;
  margin: 30px 0;
  text-align: left;
}

.perspective-fullscreen {
  background-image: url(/img/vid/perspective_help.svg);
  /* border: 3px solid #222; */
  text-align: center;
  color: #FFF;
  text-decoration: underline;
  cursor: pointer;
  text-decoration: none;
  background-repeat: no-repeat;
  margin: 0 auto;
  width: 75%;
  height: 16vw;
  position: relative;
  /* border: 1px solid #999999; */
  width: 100%;
  height: 150px;
  background-size: contain;
  background-position: center;
}

.perspective-caption {
  height: 23px;
  width: 178px;
  background-image: url(/img/black.png);
  margin: 98px auto 0 auto;
  border-radius: 0 0 5px 5px;
}

.perspective-box {
  width: 200px;
  height: 425px;
  margin: 95px auto 0 auto;
}

.perspective-box h2 {
  color: #65656a;
  font-size: 22px;
  font-weight: bold;
  line-height: 26pt;
  text-align: center;
  text-transform: uppercase;
}

.perspective-box p {
  text-align: left;
  font-size: 13px;
  line-height: 17px;
  padding-left: 5px;
  font-weight: 500;
  color: #58585a;
}

#im-floor {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  left: 0;
  right: 0;
  top: 0;
  z-index: 220;
  display: none;
  /*box-shadow: 5px 0px 10px -5px #585858;*/
}

.floor-area ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.floor-area ul li.active {
  color: #483698;
}

.divider {
  width: 93%;
  /* height: 10px; */
  border-top: solid 1px #f2f2f2;
  margin: 6px auto;
  flex: 0 0 auto;
}

.step {
  text-align: center;
  color: #434343;
  font-size: 17px;
  flex: 0 0 auto;
}

.area {
  text-align: center;
  font-size: 22px;
  color: #434343;
  margin-top: 4px;
  font-weight: 700;
  text-transform: Uppercase;
  flex: 0 0 auto;
}

.choose-tool {
  width: 100%;
  margin: 20px 0;
  padding: 0 10px;
}

.choose-tool img {
  width: 6vmin;
  height: 6vmin;
  margin-bottom: 5px;
}

#painttool ul li, #erasetool ul li {
  width: 100% !important;
  border: solid 1px #434343;
}

#painttool ul li img, #erasetool ul li img {
  width: 100% !important;
  height: 100%;
}

.choose-tool ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
}

.choose-tool ul li.active {
  color: #ffffff;
  background-color: #EC2129;
  border: solid 1px #EC2129;
}

.choose-tool ul li.middle {
  width: 48%;
  /* border-left: solid 1px #DBDBDB; */
}

.choose-tool ul li {
  width: 48%;
  color: #ffffff;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  border: solid 1px #454545;
  margin: 1px;
  background-color: #454545;
}

.choose-tool ul li i {
  font-size: 4vmin;
}

ul.paint-tool, ul.erase-tool {
  display: flex;
  flex-flow: column nowrap;
}

li.left {
  /*border-right: solid 1px #DBDBDB;
  border-bottom: solid 1px #DBDBDB;*/
  /* margin-bottom: 10px; */
}

li.rights {
  /*border-left: solid 1px #DBDBDB;
  border-top: solid 1px #DBDBDB;*/
  /* margin-bottom: 10px; */
}

/*VIDEO HELP DIV - WILL SHOW LATER */
.tool-fullscreen {
  text-align: center;
  color: #FFF;
  text-decoration: underline;
  cursor: pointer;
  text-decoration: none;
  background-repeat: no-repeat;
  margin: 0 auto;
  position: relative;
}

.tool-caption {
  /*background-color: rgba(0, 0, 0, 0.75);*/
  font-size: 1.5vh;
  padding: 3% 2% 2%;
  margin-top: -2%;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  bottom: 0px;
}

.tool-caption {
  display: none;
}

.tool-text {
  font-size: 14px;
  text-align: left;
  color: #434343;
  width: 81%;
  box-sizing: border-box;
  padding: 0;
  margin: 10px auto 0;
}

div.floor-tools div.tool-text ol, div.floor-tools div.tool-text p {
  display: none;
}

div.floor-tools div.tool-text ol {
  padding: 0 0 0 14px;
}

div.floor-tools.outline div.tool-text .useDraw {
  display: block;
}

div.floor-tools.paint div.tool-text .usePaint {
  display: block;
}

div.floor-tools.erase div.tool-text .useErase {
  display: block;
}

div.floor-tools.fill div.tool-text .useWand {
  display: block;
}

div.floor-tools.outline .tool-fullscreen {
  background: url(/img/vid/outline_help.svg) center no-repeat;
  width: 100%;
  height: 150px;
  background-size: contain;
}

div.floor-tools.paint .tool-fullscreen {
  background: url(/img/vid/paint_help.svg) center no-repeat;
  width: 100%;
  height: 150px;
  background-size: contain;
}

div.floor-tools.erase .tool-fullscreen {
  background: url(/img/vid/erase_help.svg) center no-repeat;
  width: 100%;
  height: 150px;
  background-size: contain;
}

div.floor-tools.fill .tool-fullscreen {
  background: url(/img/vid/fill_help.svg) center no-repeat;
  width: 100%;
  height: 150px;
  background-size: contain;
}

.tool-text li {
  margin-bottom: 5px;
  color: #434343;
  font-size: 12px;
}

.tool-sc {
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

#im-angle {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  left: 0;
  right: 0;
  top: 0;
  z-index: 210;
  display: none;
  /*box-shadow: 5px 0px 10px -5px #585858;*/
}

div#im-floor-main {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}

.floor-area {
  width: 99%;
  flex: 0 0 auto;
  margin: 0 5px 0 5px;
  padding-top: 5px;
}

.floor-area ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.floor-area ul li.selected {
  color: #434343;
}

.floor-area ul li.active {
  color: #ffffff;
  font-weight: 700;
  background-color: #434343;
  border: solid 1px #ffffff;
}

.floor-area ul li {
  font-size: 12px;
  cursor: pointer;
  display: inline-block;
  color: #434343;
  text-align: center;
  cursor: pointer;
  font-weight: 200;
  padding: 4px 0;
  width: 48%;
  font-weight: 700;
}

.floor-area ul li:hover {
  background-color: #434343;
  color: #ffffff;
}

.floor-tools {
  width: 100%;
  margin: 0;
  overflow-y: auto;
  padding-bottom: 2%;
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 auto;
}

[class^=slider] {
  display: inline-block;
  margin-bottom: 4px;
}

.slidercontain {
  width: 90%;
  margin-left: 5px;
  margin-bottom: 0 !important;
  color: #ffffff;
  display: flex;
  flex-flow: row nowrap;
}

.width {
  float: left;
  font-weight: bold;
  font-size: 12px;
  margin-left: 10px;
  padding-right: 5px;
  color: #434343;
}

.slider-center {
  width: 90%;
  /*height: 20px;*/
  float: left;
  display: flex;
  flex-flow: row nowrap;
  flex: 1 0 auto;
}

.slider-volume {
  width: 42% !important;
  width: 70% !important;
}

.slider-center input.value {
  position: relative;
  float: right;
  text-align: center;
  font-size: 12px;
  flex: 0 0 auto;
  min-width: 40px;
}

.feet {
  float: left;
  font-weight: bold;
  font-size: 14px;
  color: #434343;
  fleX: 0 0 auto;
  margin-left: 10px;
}

div#sldWidth-slider {
  flex: 1 0 auto;
}

div#sldHeight-slider {
  flex: 1 0 auto;
}

.tool-ss {
  height: 50px;
  width: 100%;
  margin: 2% 2% 2% 3%;
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  z-index: 997;
}

#modPop {
  width: 100%;
  max-width: 440px;
  background-color: #FFF;
  padding: 30px;
  z-index: 999;
  box-shadow: 0px 6px 15px 0px rgba(50, 50, 50, 0.5);
  overflow: hidden;
  overflow-y: auto;
  display: none;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 100px;
  border: solid 1px #434343;
}

#modWarning {
  background-color: #f3f3f3;
  position: absolute;
  padding: 25px;
  z-index: 999;
  box-shadow: 0px 6px 15px 0px rgba(50, 50, 50, 0.5);
  overflow: hidden;
  overflow-y: auto;
  display: none;
  position: absolute;
  margin: 0 auto;
  top: 100px;
  left: 0;
  right: 0;
  width: 75%;
  max-width: 460px;
}

#modWarning-headline {
  color: #434343;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
}

#modWarning-text {
  font-size: 17px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 15px;
}

.modWarning-button {
  cursor: pointer;
  padding: 10px;
  background-color: #EC2129;
  margin: 1vmin 1vmin 1vmin 0;
  text-align: center;
  color: #ffffff !important;
  /* line-height: 2vmin; */
  font-size: 18px;
  font-weight: 200;
  width: auto;
  min-width: 75%;
  border: solid 1px #EC2129;
}

.modWarning-button:hover {
  background-color: #454545;
  border: solid 1px #454545;
}

#modPop-headline {
  text-align: left;
  font-weight: 200;
  color: #434343;
  float: left;
  width: 100%;
}

#modPop-newsurfacelist {
  color: #434343;
  margin-bottom: 20px;
  font-size: 20px;
  display: flex;
  flex-flow: row wrap;
}

.modPop-close {
  height: 25px;
  width: 50px;
  color: #9d9d9d;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 402px;
  display: none;
}

div#modPop-newsurfacelist span {
  width: 100%;
  margin-bottom: 13px;
  color: #434343;
  font-size: 32px;
  line-height: 32px;
  font-style: normal;
}

.btn-modPop {
  color: #ffffff;
  font-size: 12px;
  border: solid #fff 1px;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  width: auto;
  margin: 0 5px 0 1px;
  text-transform: uppercase;
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJod…EiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+");
  background-color: #EC2129;
  padding: 8px 10px;
  border: none;
}

.btn-modPop.dark {
  background: #65656a;
  color: #fff;
}

.btn-modPop.grey {
  background: #434343;
  color: #fff;
  margin-bottom: 6px;
  font-weight: 400;
}

.mp {
  color: #434343;
  font-size: 16px;
  line-height: 50px;
  font-weight: 700;
  display: inline-block;
  margin-right: 7px;
  /*vertical-align: middle;*/
}

.mp.grey {
  color: #999;
}

.modPop-rule-sm {
  border-bottom: 2px solid #000;
  width: 276px;
  margin: 19px auto;
}

.btnsave-purple {
  font-size: 14px;
  padding: 13px 15px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  text-align: center;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #EC2129;
  margin-top: 7px;
  text-transform: uppercase;
  border-radius: 0px;
  box-sizing: border-box;
  font-size: 14px;
  width: 93%;
  border: solid 2px #EC2129;
  font-size: 18px;
  font-weight: 200;
  width: auto;
  min-width: 93%;
  font-weight: 400;
}

.buttonWrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.btnsave-purple.active {
  font-size: 14px;
  padding: 13px 15px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  text-align: left;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #EC2129;
  margin-top: 7px;
  text-transform: uppercase;
  border-radius: 0px;
  box-sizing: border-box;
  font-size: 14px;
  width: 93%;
  margin-right: 0;
  cursor: pointer;
  font-size: 18px;
  font-weight: 200;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  border: 1px solid #EC2129;
  transition: 50ms all ease-out;
}

.btnsave-purple.active:hover {
  /*background-color: $blue;*/
}

.ok.viz-button.span2.btnsave-purple.active.hvr-trim {
  background-color: #EC2129 !important;
}

/*.btnsave-purple.active:hover {
   background-color:$white !important;
}*/
.btnsave-purple.active.green {
  background-color: #454545;
  color: #ffffff;
  position: relative;
  border: none;
  border: solid 1px #454545;
}

div#btnsaveAndContinue {
  background-color: #EC2129;
  color: #ffffff;
  position: relative;
  cursor: pointer;
  font-size: 18px;
  font-weight: 200;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  border: 1px solid #EC2129;
  transition: 50ms all ease-out;
}

div#btnsaveAndContinue:hover {
  background-color: #EC2129;
  color: #ffffff;
}

.btnsave-grey {
  font-size: 14px;
  padding: 13px 15px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  text-align: left;
  border-radius: 5px;
  box-sizing: border-box;
  margin-top: 7px;
  text-transform: uppercase;
  border-radius: 0px;
  box-sizing: border-box;
  font-size: 14px;
  width: 93%;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  border: solid 2px #969696;
  text-transform: uppercase;
  text-align: center;
  background-color: #454545;
  border: 1px solid #454545;
  transition: 50ms all ease-out;
}

.btnsave-grey:hover {
  /*background-color: $blue;
  color: $white;*/
}

hr.skinny {
  padding: 0;
}

.btnim-purple {
  font-size: 14px;
  padding: 13px 15px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  text-align: center;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #EC2129;
  margin-top: 7px;
  text-transform: uppercase;
  border-radius: 0px;
  box-sizing: border-box;
  font-size: 14px;
  width: 93%;
  font-weight: 400;
  border: 1px solid #EC2129;
  transition: 50ms all ease-out;
}

/*.btnim-purple:hover {
    background-color: $blue;
    color: $white;
}*/
.btnmapping {
  color: #434343;
  display: flex;
  margin-right: 20px;
  cursor: pointer;
  font-size: 14px;
  flex-flow: row nowrap;
}

.output {
  color: #000;
  font-size: 14px;
  font-weight: bold;
  padding-top: 1px;
  margin-left: 5px;
  vertical-align: top;
  border: solid 1px #65656a;
  border-radius: 5px;
  padding: 4px;
}

.currentSurfaceTitle {
  display: none;
  color: #434343;
  font-size: 32px;
  line-height: 32px;
  font-style: normal;
}

tool-fullscreen {
  height: 119px;
  width: 141px;
  background-image: url(/img/fullscreen.jpg);
  margin: 10px 0 10px 70px;
  border-radius: 5px;
  border: 1px solid #483698;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  text-decoration: underline;
  line-height: 23px;
  cursor: pointer;
}

.vid-modal {
  background-color: #FFF;
  z-index: 1100;
  display: none;
  box-shadow: 0px 6px 15px 0px rgba(50, 50, 50, 0.5);
  background-repeat: no-repeat;
  background-repeat: repeat-x;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 2vmin;
}

.vid-modal.outline {
  display: none;
}

.vid-modal.paint {
  display: none;
}

.vid-modal.wand {
  display: none;
}

.vid-modal.erase {
  display: none;
}

.vid-modal.perspective {
  display: none;
}

.vid-modal-headline {
  text-align: left;
  font-size: 36px;
  font-weight: 400;
  color: #434343;
  margin-right: 50px;
  font-style: normal;
}

.vid-modal-close {
  color: #434343;
  cursor: pointer;
  font-size: 0;
  position: absolute;
  right: 10px;
  top: 10px;
}

.vid-modal-close:hover {
  color: #333333;
}

.vid-modal-close img {
  width: 25px;
  height: 25px;
}

.vid-modal-box {
  width: 78vmin;
  height: 52vmin;
  margin: 21px auto;
  text-align: center;
  display: block;
  background-image: url(/img/vid/Crop.gif);
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

.vid-modal-box.outline {
  background-image: url(/img/vid/outline.gif);
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

.vid-modal-box.paint {
  background-image: url(/img/vid/paint.gif);
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

.vid-modal-box.wand {
  background-image: url(/img/vid/fill.gif);
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

.vid-modal-box.erase {
  background-image: url(/img/vid/erase.gif);
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

.vid-modal-box.perspective {
  background-image: url(/img/vid/perspective.gif);
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

.vid-text {
  color: #434343;
  font-weight: 400;
  display: block;
  text-align: left;
  font-size: 14px;
}

/*overlay*/
#vid-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.76);
  width: 100%;
  height: 100%;
  display: none;
  z-index: 1099;
}

/* ChamModal  */
.chamModal {
  background: url("/ChamWebTools/JS/ChamModal/1/overlay.png") repeat left top;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  display: none;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-drag: none;
  user-drag: none;
}

.chamModal .modalInner {
  position: absolute;
  background: #e5e5e5;
  border: 2px solid #cfcfcf;
  left: 0;
  top: 100px;
  margin: 0 auto;
  right: 0;
}

.chamModal .modalInner.Draggable {
  background: #ffffff;
  border: solid 1px #434343;
  color: #555;
  left: 0;
  top: 100px;
  margin: 0 auto;
  right: 0;
  border-radius: 0px;
}

.chamModal .modalTitleBar {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border: none;
  background: none;
  text-align: right;
  z-index: 20;
}

.chamModal .modalTitleBar .title, .chamModal .modalTitleBar .closeBTN {
  display: inline-block;
  zoom: 1;
  *display: inline;
  vertical-align: top;
  position: relative;
  color: #fff;
  padding: 0;
  margin: 0;
}

.chamModal .modalTitleBar .title {
  font-size: 20px;
  text-align: left;
  width: auto;
  float: left;
  text-indent: 10px;
}

.chamModal .modalTitleBar .closeBTN {
  font-size: 50px !important;
  cursor: pointer;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #cccccc;
  top: 1px;
  right: 3px;
  font-weight: 400;
  color: #434343;
}

.chamModal .modalTitleBar .closeBTN:hover {
  color: #333333;
}

.chamModal .modalContent {
  padding: 10px;
  margin-left: 2%;
  margin-bottom: 3%;
}

.chamModal .modalContent .button {
  cursor: pointer;
}

.chamModal .modalContent .question {
  margin-bottom: 12px;
  font-size: 16px;
  text-align: center;
  color: #434343;
}

.rightPadding {
  float: right;
  padding-top: 2px;
}

.newSurface {
  font-size: 14px;
  color: #434343;
  text-transform: none !important;
  font-weight: 400;
}

.newSurface div {
  margin-bottom: 15px;
}

.newSurface div:last-child {
  margin-bottom: 0;
}

.gone {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.gone i {
  font-size: 18px;
  right: 15px;
  top: 15px;
  color: #cccccc;
}

.gone i:hover {
  color: #333333 !important;
}

.gone img {
  width: 16%;
  height: 10%;
  float: right;
}

.goright i {
  padding-left: 6px;
  font-size: 17px;
}

#panTip {
  width: 100%;
  height: auto;
  display: block;
  padding: 5px 5px 5px 10px;
  color: #434343;
  font-size: 11px;
  box-sizing: border-box;
}

.panIcon {
  float: left;
  padding-right: 10px;
}

.panIcon i {
  font-size: 32px;
  color: #434343;
}

.widthSelect {
  position: absolute;
  width: 140px !important;
  height: 155px;
  z-index: 999;
  border: 1px solid #fff;
  display: none;
  background-color: #ffffff;
  margin-top: 7px;
  margin-left: 0px;
  clear: both;
}

.widthSelect li {
  height: 49px !important;
  padding: 0px !important;
  clear: both;
}

.slider-box {
  margin: 0 auto !important;
  width: 100%;
  padding-left: 10px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
}

/*THIS WILL MAKE IM IMAGE RESPONSIVE */
.im-box {
  width: 100% !important;
}

#im-view, .im-overlay {
  width: 100% !important;
}

.im-overlay {
  /*max-height: 875px;
  overflow-y: auto;
  overflow-x: hidden;*/
}

#map-view {
  width: 85% !important;
  background-color: #ffffff;
}

#mapping-box {
  width: 100% !important;
}

#im-view {
  width: 100% !important;
}

.imap, #im-tools {
  width: 25% !important;
}

#viz, #viz .ImageMapper, #viz .ImageMapper canvas {
  width: 100% !important;
}

/* , #viz .ImageMapper canvas */
.mobile-help {
  display: none;
}

/*END THIS WILL MAKE IM IMAGE RESPONSIVE */
/* MISC STYLES
   ========================================================================== */
.clearfix {
  display: inline-block;
}

.clearfix:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}

.clearfix {
  display: block;
}

#my-projects {
  display: none;
}

/* Simple Slider
   ========================================================================== */
.slider {
  width: 300px;
}

.slider > .dragger {
  background: #8DCA09;
  background: -webkit-linear-gradient(top, #8DCA09, #72A307);
  background: -moz-linear-gradient(top, #8DCA09, #72A307);
  background: linear-gradient(top, #8DCA09, #72A307);
  -webkit-box-shadow: inset 0 2px 2px rgba(255, 255, 255, 0.5), 0 2px 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 2px 2px rgba(255, 255, 255, 0.5), 0 2px 8px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 2px 2px rgba(255, 255, 255, 0.5), 0 2px 8px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid #496805;
  width: 16px;
  height: 16px;
}

.slider > .dragger:hover {
  background: -webkit-linear-gradient(top, #8DCA09, #8DCA09);
}

.slider > .track, .slider > .highlight-track {
  background: #ccc;
  background: -webkit-linear-gradient(top, #bbb, #ddd);
  background: -moz-linear-gradient(top, #bbb, #ddd);
  background: linear-gradient(top, #bbb, #ddd);
  -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  border: 1px solid #aaa;
  height: 4px;
}

.slider > .highlight-track {
  background-color: #8DCA09;
  background: -webkit-linear-gradient(top, #8DCA09, #72A307);
  background: -moz-linear-gradient(top, #8DCA09, #72A307);
  background: linear-gradient(top, #8DCA09, #72A307);
  border-color: #496805;
}

.slider-volume {
  width: 80%;
}

.slider-volume > .dragger {
  width: 18px;
  height: 18px;
  margin: 0 auto;
  border: 1px solid rgba(255, 255, 255, 0.6);
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #EC2129;
}

.slider-volume > .track, .slider-volume > .highlight-track {
  height: 8px;
  background: #FFFFFF;
  border: 1px solid #999999;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.slider-volume > .highlight-track {
  background-color: #c5c5c5;
  background: -moz-linear-gradient(top, #c5c5c5, #a2a2a2);
  background: -webkit-linear-gradient(top, #c5c5c5, #a2a2a2);
  background: linear-gradient(top, #c5c5c5, #a2a2a2);
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  html {
    position: relative !important;
    /*overflow-y: hidden;*/
  }
  body {
    /*height: auto !important;*/
    background-color: #F2F2F2;
    position: absolute !important;
    top: 0;
  }
  section#MAIN {
    max-height: none;
    background-color: #ffffff;
  }
  #im-container {
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
  }
  #im-tools {
    width: 100% !important;
    position: static;
  }
  #im-tools > div {
    position: static;
  }
  #map-box {
    background-color: #F2F2F2;
    position: static;
    display: none;
  }
  #map-view {
    width: 100% !important;
    float: none;
  }
  .choose-tool ul li {
    width: 24% !important;
    position: relative;
  }
  .choose-tool ul li:last-child .widthSelect {
    right: 0;
  }
  .tool-fullscreen, .tool-text {
    display: none;
  }
  #viz {
    z-index: 100 !important;
  }
  .map-view-top {
    margin: 0;
    padding: 0 0 10px;
    background-color: #ffffff;
  }
  .map-view-top .btnChangeRoom {
    text-align: center;
    -moz-border-radius: 5px;
    box-sizing: border-box;
    display: inline-block;
    width: auto;
    font-size: 14px;
    color: #000000;
    cursor: pointer;
    text-transform: uppercase;
    padding: 10px 30px;
    background-color: #ffffff;
    border: solid 1px #000000;
    margin: 1px;
  }
  #mapping-box {
    border: 2px solid #ffffff;
  }
  .mapping-nav {
    margin: 5px 0 0 0;
    padding: 0 10px;
    justify-content: center;
    flex-wrap: wrap;
    background-color: #ffffff;
  }
  .mapping-nav-left {
    max-width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .mapping-nav-left > div {
    margin-bottom: 5px;
  }
  .mapping-nav-right {
    margin-bottom: 15px;
  }
  .mapping-nav-right .btnmapping:last-child {
    margin-right: 0;
  }
  .floor-area {
    width: 100%;
    margin: 0;
  }
  .floor-area ul {
    display: flex;
  }
  .floor-area ul li {
    width: 50%;
    padding: 6px 0;
  }
  .floor-tools {
    padding-bottom: 0;
    overflow: visible;
  }
  .choose-tool {
    margin: 4px 0 15px;
  }
  .tool-sc {
    margin-bottom: 10px;
  }
  .tool-ss {
    height: auto;
    margin: 6px 0 8px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .perspective-fullscreen {
    display: none;
  }
  #btnAddEditSurface {
    margin-top: 10px;
  }
  /* Crop page mobile */
  #im-box, #im-view, .im-view-top {
    background-color: #F2F2F2;
  }
  #im-box {
    width: 100%;
    margin-left: 0;
    position: static;
  }
  #im-view {
    overflow: hidden;
  }
  .crop-box p {
    margin-bottom: 15px;
  }
  .crop-fullscreen {
    /*display: none;*/
    height: 100px;
  }
  .btnim-purple {
    margin: 0 auto;
    font-size: 14px !important;
    display: block;
  }
  #btnChangeRoom, #btnRotate {
    margin-bottom: 5px;
  }
  .im-view-top .ctr {
    max-width: 93%;
    margin: 10px auto 20px;
    padding: 0;
  }
  .im-view-top .ctr .btnim {
    padding-left: 20px;
    padding-right: 20px;
  }
  .im-overlay {
    text-align: center;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  }
  .im-overlay .jcrop-holder {
    display: inline-block;
  }
  #panTip {
    display: none;
  }
  /* Mobile Help ? Marks */
  .title-wrapper {
    position: relative;
  }
  .mobile-help {
    width: auto !important;
    height: auto !important;
    color: #CE1126;
    font-size: 1.5em;
    font-weight: bold;
    line-height: 1;
    background-image: none;
    display: block !important;
    position: absolute;
    top: 0;
    right: 10px;
  }
  #zoom-controls {
    max-width: 300px;
  }
  .color-slider .slider-volume {
    width: 90% !important;
  }
}
@media only screen and (max-width: 767px) and (orientation: landscape) {
  body {
    height: 100% !important;
    /*overflow-y: auto;*/
    min-height: 380px !important;
  }
  #my-projects {
    display: none;
  }
  #panTip, .tool-text {
    display: none;
  }
  #btnsaveAndContinue .angle-right {
    display: none;
  }
  .floor-area ul li {
    font-size: 12px;
  }
  .area {
    font-size: 16px;
  }
  .choose-tool {
    margin: 10px 0;
  }
  div.floor-tools.outline .tool-fullscreen {
    height: 100px;
  }
  .mapping-nav {
    flex-flow: column nowrap;
  }
  .mapping-nav-right {
    margin-top: 20px;
  }
  .btnim {
    padding: 10px 20px;
  }
  div#im-floor {
    height: auto;
  }
  .btnsave-purple {
    margin-bottom: 10px;
  }
  div#im-angle {
    height: 160%;
  }
  .slider-center {
    flex-flow: column nowrap;
  }
  .slider-volume {
    width: 90% !important;
  }
  .btnsave-grey {
    padding: 10px 5px;
    font-size: 11px;
  }
  .btnsave-purple.active {
    font-size: 11px;
  }
  .perspective-fullscreen {
    height: 100px;
  }
  .step {
    font-size: 15px;
  }
  .slidercontain {
    align-items: flex-end;
  }
  .widthSelect {
    width: 80px !important;
    height: 84px !important;
  }
  .widthSelect li {
    height: 27px !important;
  }
  ul.paint-tool li, ul.erase-tool li {
    width: 100%;
  }
  ul.paint-tool li img, ul.erase-tool li img {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .mapping-nav {
    flex-flow: column nowrap;
  }
  .mapping-nav-right {
    margin-top: 20px;
  }
  .slider-volume {
    width: auto !important;
  }
  .slider-center {
    width: auto !important;
  }
  .color-slider .slider-volume {
    width: 90% !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .crop-fullscreen {
    width: 100%;
  }
  .btnim {
    padding: 10px 20px;
  }
  .btnsave-grey {
    font-size: 12px;
  }
  .btnsave-purple.active {
    font-size: 12px;
  }
  .floor-area ul li {
    font-size: 11px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  #panTip {
    display: none;
  }
}
div.wait {
  background-image: url(../../img/25.gif) !important;
  background-color: none !important;
  z-index: 10000;
  position: absolute;
  text-align: center;
  background-repeat: no-repeat !important;
  background: rgba(0, 0, 0, 0.75);
  width: 100% !important;
  height: 100% !important;
  left: 0 !important;
  top: 0 !important;
  background-position: center;
}

/*MODAL HIDE*/
#successOverlay {
  display: none;
}

#congratsOverlay {
  display: none;
}

#confirmOverlay {
  display: none;
}
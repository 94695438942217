/* ----------------------------------------------------------------
Visualizer
-----------------------------------------------------------------*/

@import '../framework/breakpoint';

.visualizer-wrapper {
  display: none;
  width: 100%;
  height: auto;
  flex: 1 1 auto;
  display: flex !important;
  flex-flow: column nowrap;
  min-height: 0;
}

header {
  position: relative;
}

header .landing, header .visualizer-wrapper {
  width: 100%;
  height: auto;
  /*position: absolute;
    top: 0;
    left: 0;*/
  flex: 0 0 auto;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: #ededed;
  padding: 10px;
}

header .landing, header .visualizer {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-flow: row nowrap;
}

header nav.visualizer div {
  display: inline-block;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  margin-right: 30px;
}

header nav.visualizer div:last-child {
  margin-right: 0;
}

header nav.visualizer i.fa {
  display: block;
  font-size: 2.5vh;
  cursor: pointer;
}

section.visualizer {
  width: 100%;
  height: auto;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  min-height: 0;
}

div.visualizer-pane {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  min-height: 0;
}

/*div.product-menu {
    width: 31.5%;
    height: 100%;
    position: relative;
    overflow: hidden;
}*/

.viz-holder {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
}

#visualizer {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  flex: 1 1 auto;
}

#visualizer-controls {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  z-index: 3000;
}

#visualizer-controls button {
  margin-right: 2px;
  padding: 6px 8px;
  border: none;
  cursor: pointer;
  font-size: 2vmin;
}

#visualizer-controls button i {
  cursor: pointer;
}

#visualizer-controls button div {
  font-size: 0.8em;
  font-weight: bold;
  cursor: pointer;
}

#visualizer-controls button:focus {
  outline: none;
}

.footer {
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;

  div#print {
    height: 50px;
    background-color: #ed1b24;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    flex: 0 0 10%;

    i {
      font-size: 33px;
      margin-right: 15px;
      color: #ffffff;
    }

    p {
      color: #ffffff;
      font-size: 14px;
    }
  }

  nav#share-icons {
    display: flex;
    flex-flow: row nowrap;
    flex: 0 0 30%;
    justify-content: center;

    a, div {
      width: 35px;
      min-width: 35px;
      height: 35px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      border-radius: 5px;
      cursor: pointer;

      i {
        line-height: 1;
      }
    }

    div {
      margin-right: 10px;
    }

    div#facebookLink {
      border: solid 2px #3b5998;

      img {
        width: 19px;
      }
    }

    div#twitterLink {
      border: solid 2px #1da1f2;

      img {
        width: 20px;
      }
    }

    div#pinterestLink {
      border: solid 2px #BD081C;

      img {
        width: 19px;
      }
    }

    div#email-btn {
      border: solid 2px #0a703f;

      img {
        width: 20px;
      }
    }
  }
}

div#calc-button {
  background-color: #ed1b24;
  height: 50px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 10px;
  cursor: pointer;

  i {
    font-size: 28px;
    margin-right: 15px;
    color: #ffffff;
  }

  p {
    color: #ffffff;
    font-size: 14px;
  }
}


div#visualizer-zoom {
  position: absolute;
  z-index: 9999;
  bottom: 10px;
  left: 10px;

  button {
    background-color: transparent;
    border: none;
    width: 40px;
    margin: 0;
    padding: 0;
    cursor: pointer;

    &:first-child {
      margin-right: 10px;
    }
  }
}

div#visualizer-undo {
  position: absolute;
  z-index: 9999;
  bottom: 10px;
  right: 10px;

  button {
    background-color: transparent;
    border: none;
    width: 40px;
    margin: 0;
    padding: 0;
    cursor: pointer;

    &:first-child {
      margin-right: 10px;
    }
  }
}


.surface-menu {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;

  ul {
    display: flex;
    flex-flow: row nowrap;

    li {
      background-color: #434343;
      color: #ffffff;
      font-size: 15px;
      margin-right: 10px;
      width: 145px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-transform: uppercase;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        background-color: #ed1b24;
      }
    }
  }
}


div#brick-menu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  height: 100%;
  /*width: 560px;*/
  width: 594px;
  background-color: #ffffff;
  display: flex;
  flex-flow: row nowrap;
  box-shadow: 1px -1px 5px 0px #333333;
  border: solid 1px rgba(51, 51, 51, 0.18);
  transform: translateX(-105%);
  transition: .4s transform ease-out;

  &.active {
    transform: translateX(0);
    transition: .4s transform ease-out;
    z-index: 999999;
  }
}

.side-surface {
  background-color: #ededed;
  padding: 34px 10px 10px 10px;
  flex: 0 0 auto;
  overflow-y: auto;

  ul {
    display: flex;
    flex-flow: column nowrap;

    li {
      width: 150px;
      height: 40px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      background-color: #434343;
      color: #ffffff;
      text-transform: uppercase;
      font-size: 15px;
      margin-bottom: 10px;
      cursor: pointer;
      text-align: center;

      &:last-child {
        margin-bottom: 0;
      }

      &.active {
        background-color: #ed1b24;
      }
    }
  }
}



div.product-menu {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  padding: 10px;
  min-height: 0;
  width: 100%;


  div#close-brick {
    width: 14px;
    height: 14px;
    align-self: flex-end;
    display: flex;
    flex-flow: column nowrap;
    flex: 0 0 auto;
    margin-bottom: 10px;
    cursor: pointer;

    img {
      width: 100%;
    }
  }

  div#close-trim {
    width: 14px;
    height: 14px;
    align-self: flex-end;
    display: flex;
    flex-flow: column nowrap;
    flex: 0 0 auto;
    margin-bottom: 10px;
    cursor: pointer;

    img {
      width: 100%;
    }
  }

  div#close-roof {
    width: 14px;
    height: 14px;
    align-self: flex-end;
    display: flex;
    flex-flow: column nowrap;
    flex: 0 0 auto;
    margin-bottom: 10px;
    cursor: pointer;

    img {
      width: 100%;
    }
  }
}

.wall-tabs {
  flex: 0 0 auto;
  border-bottom: solid 1px #797979;
  margin-bottom: 10px;
  padding-bottom: 10px;

  ul {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    li {
      flex: 0 0 22.5%;
      height: 40px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      background-color: #434343;
      color: #ffffff;
      text-transform: uppercase;
      font-size: 15px;
      margin-right: 10px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        background-color: #ed1b24;
      }
    }
  }
}



.brick-products {
  display: none;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  min-height: 0;


  &.active {
    display: flex;
  }
}

.stone-products {
  display: none;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  min-height: 0;


  &.active {
    display: flex;
  }
}

.block-products {
  display: none;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  min-height: 0;


  &.active {
    display: flex;
  }
}

.mortar-products {
  display: none;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  min-height: 0;


  &.active {
    display: flex;
  }

  ul {
    display: flex;
    flex-flow: row wrap;
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    align-content: flex-start;

    li {
      cursor: pointer;
      flex: 0 0 31.5%;
      margin-bottom: 10px;
      margin-right: 5px;
      /*&:nth-child(3n+3) {
                margin-right: 0;
            }*/
      h1 {
        font-size: 15px;
        font-weight: 600;
        line-height: 1;
        padding-top: 5px;
      }

      .mortar-color {
        width: 100%;
        height: 115px;
        /*background-color: #333333;*/
        position: relative;

        img {
          width: 100%;
          height: 115px;

/*          @include breakpoint (phone, tablet, laptop) {
            width: 189px;
          }

          @include breakpoint (phone portrait) {
            width: 100%;
          }

          @include breakpoint (phone landscape) {
            width: 261px;
          }

          @media only screen and (min-width: 376px) and (max-width: 428px) and (orientation: portrait) {
            width: 100%;
          }*/
        }
      }

      &.active {
        .mortar-color::after {
          content: '';
          width: 100%;
          height: 100%;
          border: solid 4px #ed1b24;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}

.product-list ul.mortar {
  justify-content: space-between;

  li.mortar {
    flex: 0 0 48%;
    /*    @include breakpoint (tablet portrait) {
      flex: 0 0 23%;
    }

    @include breakpoint(phone portrait) {
      flex: 0 0 45%;
    }*/
  }
}



.product-filter {
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 10px;

  select {
    flex: 1 1 auto;
    height: 30px;

    &:first-child {
      margin-right: 10px;
    }
  }
}


.product-list {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  min-height: 0;


  ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    align-content: flex-start;


    li {
      cursor: pointer;
      flex: 0 0 48.5%;
      margin-bottom: 10px;
      /*margin: 0px 2px 10px 2px;*/
      /*&:nth-child(odd) {
                margin-right: 10px;
            }*/
      img {
        width: 100%;
        position: relative;
      }

      h1 {
        font-size: 15px;
        font-weight: 700;
        line-height: 1;
        padding-top: 5px;
      }

      p {
        font-size: 15px;
        line-height: 1;
        padding-top: 5px;
      }

      .thumb {
        position: relative;
      }

      &.active {
        .thumb::after {
          content: '';
          width: 100%;
          height: 100%;
          border: solid 4px #ed1b24;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      &.hidden {
        display: none;
      }
    }
  }
}

li.mortar {
  &.hide {
    display: none;
  }
}

li.mortar-msg {
  display: none;

  &.active {
    display: flex;
    flex: 0 0 98%;
    color: #ed1b24;
    text-transform: uppercase;
    cursor: default;

    p {
      line-height: inherit;

      span {
        font-weight: 700;
        color: black;
      }
    }
  }
}

div#trim-menu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  height: 100%;
  width: 560px;
  background-color: #ffffff;
  display: flex;
  flex-flow: row nowrap;
  box-shadow: 1px -1px 5px 0px #333333;
  border: solid 1px rgba(51, 51, 51, 0.18);
  transform: translateX(-105%);
  transition: .4s transform ease-out;

  &.active {
    transform: translateX(0);
    transition: .4s transform ease-out;
    z-index: 999999;
  }
}

.trim-products {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  min-height: 0;


  ul {
    display: flex;
    flex-flow: row wrap;
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    align-content: flex-start;

    li {
      cursor: pointer;
      flex: 0 0 31.4%;
      margin-bottom: 10px;
      margin-right: 10px;
      min-width: 92px;

      &:nth-child(3n+3) {
        margin-right: 0;
      }

      h1 {
        font-size: 15px;
        font-weight: 600;
        line-height: 1;
        padding-top: 5px;
      }

      .mortar-color {
        width: 100%;
        height: 115px;
        /*background-color: #333333;*/
        position: relative;
      }

      &.active {
        .mortar-color::after {
          content: '';
          width: 100%;
          height: 100%;
          border: solid 4px #ed1b24;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}

html.ie .trim-products ul li {
  min-width: inherit;
}

div#roof-menu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  height: 100%;
  width: 390px;
  background-color: #ffffff;
  display: flex;
  flex-flow: row nowrap;
  box-shadow: 1px -1px 5px 0px #333333;
  border: solid 1px rgba(51, 51, 51, 0.18);
  transform: translateX(-105%);
  transition: .4s transform ease-out;

  &.active {
    transform: translateX(0);
    transition: .4s transform ease-out;
    z-index: 999999;
  }
}

.roof-products {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;

  ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;


    li {
      cursor: pointer;
      flex: 0 0 48.5%;
      margin-bottom: 10px;

      &:nth-child(odd) {
        margin-right: 10px;
      }

      img {
        width: 100%;
        position: relative;
      }

      h1 {
        font-size: 15px;
        font-weight: 600;
        line-height: 1;
        padding-top: 5px;
      }

      p {
        font-size: 15px;
        line-height: 1;
        padding-top: 5px;
      }

      .thumb {
        position: relative;
      }

      &.active {
        .thumb::after {
          content: '';
          width: 100%;
          height: 100%;
          border: solid 4px #ed1b24;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}

div#calculator-modal {
  &.lightbox {
    z-index: 999999;

    &.active {
      display: block;
    }

    .header {
      background-color: #ed1b24;
      padding: 10px;
      margin: 0;

      h2 {
        color: #ffffff;
        font-size: 24px;
        font-weight: 600;
      }

      p {
        font-size: 14px;
        color: #ffffff;
      }

      button.close {
        position: relative;
        width: 20px;
        top: auto;
        right: auto;
        transform: none;
        flex: 0 0 auto;
        cursor: pointer;
      }
    }

    .content {
      padding: 20px 10px 10px;
      display: flex;
      flex-flow: column nowrap;
    }
  }
}


div#calculator-modal.lightbox > div {
  max-width: 970px !important;
}

.title-main {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}

.calc-title {
  flex: 1 1 auto;
}

.calc-check {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;

  div {
    margin-left: 10px;
  }
}

/*input[type=checkbox].css-checkbox {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

input[type=checkbox].css-checkbox + label.css-label {
    padding-left: 25px;
    height: 20px;
    display: inline-block;
    line-height: 20px;
    background-repeat: no-repeat;
    background-position: 0 0;
    font-size: 20px;
    vertical-align: middle;
    cursor: pointer;
    color: #ffffff;
    font-size: 14px;
}

input[type=checkbox].css-checkbox:checked + label.css-label {
    background-position: 0 -20px;
}

label.css-label {
    background-image: url(/img/check.png);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}*/

.container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*font-family: 'FreightSansProBook-Regular';*/
  flex: 0 0 13%;
  color: white;
  //width: 33.333333333%;
  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
  }
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: white;
    margin-top: -1px;
  }
  /* Style the checkmark/indicator */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: white;
  }

  .checkmark {
    content: "";
    position: absolute;
    display: block;
  }
  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    left: 7px;
    top: 2px;
    width: 7px;
    height: 12px;
    border: solid red;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.choose-product {
  flex: 0 0 auto;

  h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  ul {
    display: flex;
    flex-flow: row wrap;

    li {
      display: flex;
      flex-flow: column nowrap;
      border: solid 2px #A1A1A1;
      position: relative;
      padding: 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      flex: 0 0 18.9%;
      cursor: pointer;
      justify-content: space-around;

      &.active {
        border: solid 2px #ed1b24;
      }

      &:nth-child(5n+5) {
        margin-right: 0;
      }

      p {
        font-size: 14px;
        margin: 0 !important;
        padding: 0;
      }

      img {
        width: 70%;
        margin: 0 auto;
      }

      .brick-info {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
      }

      .brick-hover {
        cursor: pointer;

        img {
          width: 15px !important;
        }

        &:hover {
          .brick-info-hover {
            display: flex;
          }

          img {
            z-index: 9;
            position: relative;
          }
        }
      }

      .brick-info-hover {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #ffffff;
        width: 100%;
        height: 100%;
        flex-flow: column nowrap;
        padding: 10px;

        p {
          font-size: 12px;
          line-height: 1.5;
        }
      }
    }
  }
}


.choose-size {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.size-side {
  flex: 0 0 65.1%;
  padding: 10px;

  h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .width-height {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    .width-size {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      margin-right: 50px;
    }

    .height-size {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
    }

    h4 {
      margin-bottom: 10px;
      font-size: 20px;
    }

    input {
      width: 100px;
      height: 30px;
    }
  }

  .area-size {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    h4 {
      margin-bottom: 10px;
      font-size: 20px;
    }

    input {
      width: 150px;
      height: 30px;
    }
  }

  .or {
    background-color: #434343;
    color: #ffffff;
    width: 150px;
    height: 40px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: 20px auto;

    p {
      margin: 0 !important;
    }
  }

  button {
    background-color: #ed1b24;
    color: #ffffff;
    width: 100%;
    height: 50px;
    margin-top: 20px;
  }
}

.total-side {
  flex: 0 0 31%;
  border: solid 1px #A1A1A1;
  padding: 10px;
  display: flex;
  flex-flow: column nowrap;

  .total-bricks {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    max-width: 270px;

    h1 {
      font-size: 48px;
      color: #ed1b24;
      font-weight: 600;
      word-break: break-all;
    }

    p {
      font-size: 23px;
      color: #ed1b24;
      font-weight: 600;
    }

    h4 {
      font-size: 28px;
    }
  }

  p {
    font-size: 13px;
    flex: 0 0 auto;
    margin: 0 !important;
  }
}






.noscroll {
  &.active {
    overflow: hidden;
  }
}


.right-foot {
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 90%;
}

.disclaimer-btn {
  display: none;
}

.disclaimer {
  margin: 0 10px;
  flex: 0 0 75%;

  p {
    font-size: .67em;
    color: #797979;
    line-height: 1.4;
  }
}

.close-disclaimer {
  display: none;
}

.disclaimer-mobile {
  margin: 0 10px 0 0;
  flex: 0 0 auto;
  display: none;

  p {
    font-size: 12px;
    color: #797979;
    line-height: 1.4;
    font-size: .67em;
    padding: 10px 0 5px;
  }
}



@media only screen and (min-width: 1441px) {
  #visualizer {
    position: absolute;
    height: 100%;
  }
}





@include breakpoint(phone ) {
  .container {
    flex: 0 0 28%;
  }

  .disclaimer {
    width: 90% !important;
    height: 90%;
    position: fixed !important;

    p {
      font-size: 14px;
      color: #454545;
      line-height: 1.4;
      padding: 20px;
    }
  }
}

@include breakpoint(phone portrait) {

  .disclaimer-btn {
    order: 2;
    /*margin-right: 0 !important;
        margin-left: 0 !important;*/
    margin-left: 30px;
  }

  .footer div#print i {
    font-size: 16px;
    margin-right: 10px;
    color: #ffffff;
  }

  .footer div#print {
    height: 40px;
  }

  body, html, #MAIN {
    height: auto !important;
  }

  html {
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }

  header .visualizer-wrapper {
    flex-flow: column nowrap;
  }

  nav.visualizer {
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
  }

  div#calc-button {
    order: 2;
    width: 100%;
    justify-content: center;
    flex: 0 0 auto;
  }

  header nav.visualizer div {
    flex: 0 0 48.8%;
    height: 40px;
    border: solid 1px #777777;
    margin: 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  .surface-menu {
    position: relative;
    order: -1;
    top: auto;
    right: auto;
    margin-bottom: 10px;

    ul {
      justify-content: space-between;
    }
  }

  .surface-menu ul li {
    width: auto;
    flex: 0 0 31.7%;
    margin: 0;
    font-size: 13px;
    line-height: 1px;
    height: 60px;
    padding: 0 10px;
    justify-content: center;
    align-items: center;
  }

  header {
    margin-bottom: 10px;
  }

  .footer {
    order: -1;
    margin: 0 0 10px 0;
    flex-flow: row wrap;
  }

  div#print {
    order: 2;
  }

  li#brick-toggle {
    line-height: 17px;
    text-align: center;
  }

  .viz-holder {
    flex: 0 0 auto;
    margin-bottom: 10px;
  }

  div#close-brick, div#close-trim, div#close-roof {
    display: none !important;
  }

  div#brick-menu {
    position: relative;
    box-shadow: none;
    flex-flow: column nowrap;
    width: 100%;
    transform: none;
    display: none;

    &.active {
      transform: none;
      display: flex;
    }
  }

  .side-surface {
    padding: 10px;

    ul {
      flex-flow: row wrap;

      li {
        margin-right: 10px;
        width: auto;
        flex: 0 0 48.3%;

        &:nth-child(even) {
          margin-right: 0;
        }
      }
    }
  }

  .wall-tabs ul li {
    height: 50px;
  }

  .product-list ul li {
    flex: 0 0 48.3%;
  }

  .mortar-products ul li {
    flex: 0 0 48.4%;
  }
  /*.mortar-products ul li:nth-child(3n+3) {
        margin-right: 10px;
    }

    .mortar-products ul li:nth-child(2n+2) {
        margin-right: 0;
    }*/
  .mortar-products ul li .mortar-color {
    height: 115px;
  }

  div#trim-menu {
    position: relative;
    box-shadow: none;
    flex-flow: column nowrap;
    width: 100%;
    transform: none;
    display: none;

    &.active {
      transform: none;
      display: flex;
    }
  }

  .trim-products ul li {
    flex: 0 0 48.4%;
  }

  .trim-products ul li:nth-child(3n+3) {
    margin-right: 10px;
  }

  .trim-products ul li:nth-child(2n+2) {
    margin-right: 0;
  }

  .trim-products ul li .mortar-color {
    height: 115px;
  }

  div#roof-menu {
    position: relative;
    box-shadow: none;
    flex-flow: column nowrap;
    width: 100%;
    transform: none;
    display: none;

    &.active {
      transform: none;
      display: flex;
    }
  }

  .calc-check {
    margin-top: 10px;
  }

  .choose-product ul li:nth-child(5n+5) {
    margin-right: 10px;
  }

  .choose-product ul li:nth-child(even) {
    margin-right: 0;
  }

  .choose-product ul li {
    flex: 0 0 48.4%;
  }

  .choose-size {
    flex-flow: column nowrap;
  }

  .size-side {
    flex: 0 0 auto;
  }

  .total-side {
    flex: 0 0 auto;
    margin-top: 10px;
  }

  .total-bricks {
    margin-bottom: 20px;
  }

  .product-filter {
    flex-flow: row wrap;
  }

  .product-filter select {
    margin: 2px;
  }

  .product-filter select:first-child {
    margin-right: 2px;
  }
}

@include breakpoint(phone landscape) {
  body, html, #MAIN {
    height: auto;
  }

  html {
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }

  header nav.visualizer div {
    font-size: 14px;
    margin-right: 20px;
  }

  div#brick-menu {
    position: fixed;
    width: 100%;
  }

  div#trim-menu {
    position: fixed;
    width: 100%;
  }

  div#roof-menu {
    position: fixed;
    width: 100%;
  }

  .wall-tabs ul li {
    flex: 0 0 24%;
  }

  .product-list ul {
    justify-content: flex-start;

    li {
      flex: 0 0 47.5%;
      margin-right: 10px;

      &:nth-child(odd) {
        margin-right: 10px;
      }

      &:nth-child(3n+3) {
        /*margin-right: 0 !important;*/
      }
    }
  }

  .mortar-products ul li {
    flex: 0 0 23.3%;
    /*&:nth-child(3n+3) {
            margin-right: 10px;
        }

        &:nth-child(4n+4) {
            margin-right: 0;
        }*/

  }

  .trim-products ul li {
    flex: 0 0 23.2%;

    &:nth-child(3n+3) {
      margin-right: 10px;
    }
    /*&:nth-child(4n+4) {
            margin-right: 0 !important;
        }*/
    .mortar-color {
      width: 100%;
      height: 110px;
    }
  }

  #roof-menu {
    .product-list ul li {
      flex: 0 0 23.7%;
      min-width: 115px;

      &:nth-child(3n+3) {
        margin-right: 10px !important;
      }
      /*&:nth-child(4n+4) {
                margin-right: 0 !important;
            }*/
    }
  }

  .calc-check {
    margin-top: 10px;
  }

  .choose-product ul li:nth-child(5n+5) {
    margin-right: 10px;
  }

  .choose-product ul li:nth-child(3n+3) {
    margin-right: 0;
  }

  .choose-product ul li {
    flex: 0 0 32.2%;
  }

  div#calculator-modal.lightbox {
    position: fixed;
  }
}

@include breakpoint(phone, tablet) {

  .choose-product ul li .brick-hover img {
    display: none;
  }

  .brick-info {
    flex-flow: column nowrap !important;
    align-items: flex-start !important;
  }

  .brick-info-hover {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    flex: 0 0 auto;
    display: flex !important;
    padding: 0 !important;

    p {
      font-weight: 400 !important;
    }
  }

  .choose-product ul li p {
    font-weight: 700;
  }

  .right-foot {
    -ms-flex: 0 0 70%;
    flex: 0 0 70%;
  }

  .footer div#print {
    -ms-flex: 0 0 15%;
    flex: 0 0 15%;
  }
  /*.disclaimer-btn {
    display: block;
    margin-right: 20px;
    margin-left: 70px;
    cursor: pointer;

    p {
      font-size: 12px;
      color: #797979;
      line-height: 1.4;
      text-decoration: underline;
    }
  }*/

  .disclaimer {
    display: none;
  }

  .disclaimer-mobile {
    display: flex;
  }

  .disclaimer p {
    display: none;
  }

  .right-foot {
    display: flex;
    flex-flow: row nowrap;
    flex: 0 0 70%;
  }

  .right-foot {
    align-items: center;
  }

  .close-disclaimer {
    display: flex;
    width: 20px;
    flex-flow: column nowrap;
    align-self: flex-end;
    cursor: pointer;
    margin: 20px;
  }

  #disclaimer-modal.lightbox {

    &.active {
      display: flex;
    }
  }
}


@include breakpoint(tablet ) {
  .viz-holder {
    flex: 0 0 auto;
  }
}

@include breakpoint(tablet portrait) {

  .disclaimer-btn {
    order: 2;
    margin-right: 0 !important;
    margin-left: 10px !important;
  }

  .disclaimer {
    top: 19%;
    width: 100%;
  }

  body, html, #MAIN {
    height: auto;
  }

  html {
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .footer {
    order: -1;
    margin: 0 0 10px 0;
  }

  div#print {
    order: 2;
  }

  .surface-menu {
    position: relative;
    top: auto;
    right: auto;
    margin-top: 20px;
    margin-bottom: 20px;

    ul li {
      width: auto;
      flex: 0 0 32.4%;
    }
  }

  div#close-brick, div#close-trim, div#close-roof {
    display: none !important;
  }

  .side-surface {
    padding: 10px;
  }

  div#brick-menu {
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    box-shadow: none;
    display: none;

    &.active {
      transform: none;
      display: flex;
    }
  }

  .product-list ul {
    overflow: visible;
  }

  .wall-tabs ul li {
    flex: 0 0 32%;
  }

  div#trim-menu {
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    box-shadow: none;
    display: none;

    &.active {
      transform: none;
      display: flex;
    }
  }

  div#roof-menu {
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    box-shadow: none;
    display: none;

    &.active {
      transform: none;
      display: flex;
    }
  }

  .wall-tabs ul li {
    flex: 0 0 23%;
  }

  .product-list ul {
    justify-content: flex-start;

    li {


      &:nth-child(odd) {
        margin-right: 10px;
      }
      /*&:nth-child(3n+3) {
                margin-right: 0 !important;
            }*/
    }
  }

  .mortar-products ul li {
    flex: 0 0 23.6%;

    &:nth-child(3n+3) {
      margin-right: 10px;
    }
    /*&:nth-child(4n+4) {
            margin-right: 0;
        }*/

  }

  .trim-products ul li {
    flex: 0 0 23.6%;

    &:nth-child(3n+3) {
      margin-right: 10px;
    }
    /*&:nth-child(4n+4) {
            margin-right: 0 !important;
        }*/
    .mortar-color {
      width: 100%;
      height: 126px;
    }
  }

  #roof-menu {
    .product-list ul li {
      flex: 0 0 23.9%;

      &:nth-child(3n+3) {
        margin-right: 10px !important;
      }
      /*&:nth-child(4n+4) {
                margin-right: 0 !important;
            }*/
    }
  }

  .calc-check {
    margin-top: 10px;
  }

  .choose-product ul li:nth-child(5n+5) {
    margin-right: 10px;
  }

  .choose-product ul li:nth-child(4n+4) {
    margin-right: 0;
  }

  .choose-product ul li {
    flex: 0 0 23.9%;
  }

  section.visualizer {
    flex: 0 0 auto;
  }
}

@include breakpoint(tablet landscape) {
  div.visualizer-pane {
    flex: 0 0 auto;
  }
}

@include breakpoint(laptop) {
  div.visualizer-pane {
    flex: 0 0 auto;
  }

  body, html, #MAIN {
    width: 100%;
    height: auto;
  }
}

﻿#applied-products {
  min-width: 25%;
  height: 82.1%;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3000;
  transform: translateX(100%) translateX(33px);
  transition: 400ms all ease-out;
}

#applied-products.open {
  transform: translateX(0);
}

#applied-products .close {
  padding: 6px 10px;
  color: white;
  border: none;
  background-color: rgb(35, 93, 123);
  font-size: 1.2em;
  position: absolute;
  top: 0;
  right: 100%;
}

#applied-products .close:focus {
  outline: none;
}

#applied-list {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 1.7vmin 2.2vmin;
  color: white;
  list-style: none;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

#applied-list li {
  padding: 8% 0;
  border-bottom: 3px solid #D4D2D3;
  display: flex;
}

#applied-list li:first-child {
  padding-top: 0;
}

#applied-list li > div {
  margin-left: 5%;
  font-size: 1.7vmin;
  display: inline-block;
  vertical-align: top;
}

#applied-list li img {
  width: 100px;
  display: inline-block;
  vertical-align: top;
  flex-basis: 100px;
}

#applied-list li .surface-name {
  font-weight: bold;
}

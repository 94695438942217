div.social {
  width: 100%;
  height: 10.4%;
  display: flex;
  justify-content: space-between;
  font-size: 2vh;
  line-height: 4.5;
  text-transform: uppercase;
}

div.feedback {
  cursor: pointer;
}

div.share {
  height: 100%;
  width: 50%;
  display: flex;
}

div.share span {
  display: inline-block;
  text-align: right;
  padding: 0 4% 0 0;
  width: 50%;
}

#share {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
}

#share a, #share div {
  display: inline-block;
  border-radius: 100%;
  width: 3.5vh;
  height: 3.5vh;
  margin: 0 1%;
  text-align: center;
  cursor: pointer;
}

#share i {
  vertical-align: 148%;
  cursor: pointer !important;
}

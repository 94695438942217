/* ==========================================================================
    VARIABLES
   ========================================================================== */
@import '../framework/breakpoint';

$red: #EC2129;
$gray: #65656a;
$medium-gray: #B3B2B2;
$light-blue: #f3f3f3;
$light-gray: #d6d7d8;
$tan: #c7b299;
$white: #ffffff;
$black: #434343;
$dark-gray: #454545;
$background: #f2f2f2;
$outline: #434343;
$blue: #EC2129;

/* ==========================================================================
    IM
   ========================================================================== */

.modal-headline {
    text-align: left;
    flex: 0 0 auto;
    color: $outline;
    font-size: 24px;
    line-height: 36px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
}

.modal-close {
    float: right;
    color: #000000;
    margin-right: 0px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 18px;
    width: 25px;
}

.modal-close:hover {
    color: #333333 !important;
}

.modal-box {
    width: 100%;
    height: auto;
    margin: 2vmin auto;
    text-align: center;
    flex: 1 0 auto;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    max-height: 460px;
}

.upload-box {
    width: 100%;
    height: auto;
    margin: 0;
    background-color: #f3f3f3;
    border: dashed 2px #B3B2B2;
    color: #65656a;
    left: 0;
    right: 0;
    z-index: 300;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
    position: absolute;
    height: 100%;
}

.upload-box p {
    font-size: 14px;
    color: $black;
    position: absolute;
    top: 61%;
    margin: 0 auto;
    width: 100%;
    left: 0;
}

.upload-box input[type="file"] {
    display: none;
}

.modal-pic {
    background-color: #EAEAEA;
    border-radius: 3px;
    z-index: 200;
    width: 100%;
    height: auto;
    flex: 0 1 auto;
    display: flex;
    flex-flow: column nowrap;
    overflow: auto !important;
    height: 600px;
}

.trash {
    background-color: $black;
    color: #FFF;
    font-size: 4vmin;
    cursor: pointer;
    border-top-right-radius: 3px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 200;
    padding: 1vmin;
    font-size: 36px;
}

.modal-pic #imgToUpload {
    width: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    flex: 1 0 auto;
}
 @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
   /* IE10+ specific styles go here */ 
   .modal-pic #imgToUpload {
    width: auto;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    flex: 1 0 auto;
} 
}

.modal-label {
    float: left;
    font-size: 16px;
    font-weight: bold;
    color: $gray;
    margin: 5px 0 0 55px;
}

.modal-input {
    padding: 10px;
    border: 1px solid #B1B1B1;
    border-radius: 5px;
    border-image: none;
    font-size: 20px;
    color: $gray;
    box-shadow: 0px 0px 0px 0px rgba(42, 42, 42, 0.75);
    margin: 10px 0 0 50px;
    width: 440px;
    float: left;
}

.project-button {
    -moz-border-radius: 5px;
    display: block;
    text-align: center;
    font-size: 20px;
}

.project-button i {
    margin-left: 10px;
}

.uploaderBTN {
    color: $white;
    text-align: center;
    cursor: pointer;
    border-radius: 1px;
    box-sizing: border-box;
    padding: 15px;
    background-color: transparent;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-size: 14px;
    border: solid 1px $dark-gray;
    background-color: $dark-gray;
}

/*.uploaderBTN:hover {
    background: $blue;
    color: $white;
}*/

div#upload-inner {
    width: 100%;
    max-width: 1155px;
    padding: 10px 10px 30px;
    background-color: $white;
    position: relative;
    display: inline-flex;
    flex-flow: column nowrap;
    background-color: $white;
}

div#upload-main {
    display: flex;
    flex-flow: row nowrap;
}

#overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000;
    width: 100%;
    height: 100%;
    display: none;
    position: absolute;
    z-index: 997;
}

#upload-container {
    padding: 10px;
}

div#close-upload {
    width: 25px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 10px;
    top: 10px;
}

#closemodal {
    cursor: pointer;
    font-size: 12px;
    color: #353334;
    color: $white;
    display: inline-flex;
    align-items: center;
}

#closemodal img {
    width: 100%;
    height: 25px;
}

#closemodal i {
    color: $white !important;
    font-size: 18px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.upload-left-head {
    font-size: 24px;
    color: $black;
    font-weight: 700;
    line-height: 36px;
    margin-top: 20px;
    font-style: normal;
    text-transform: uppercase;
}

.upload-box-left {
    width: 36%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 20px;
}

.upload-box-right {
    width: 60%;
    margin-left: 30px;
    display: flex;
    flex-flow: column nowrap;
    padding-top: 93px;
}

div#good-bad-imgs {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: 2%;
}

.upload-left-text {
    font-size: 14px;
    color: $black;
}

.upload-left-text p {
    margin: 0;
    padding: 0;
    margin-bottom: 30px;
}

.upload-list i {
    color: $blue;
    margin-left: 5px;
    margin-right: 5px;
}

.upload-list {
    font-size: 12px;
    color: $black;
}

.upload-button-box {
    width: 100%;
    text-align: center;
}

.photo-good {
    width: 48%;
    position: relative;
    box-sizing: border-box;
    background-repeat: no-repeat;
}

.photo-good img {
    width: 100%;
}

.photo-text {
    width: 100%;
    margin-top: 2%;
    float: left;
}

.star {
    color: #e11c00;
    float: left;
    width: 10px;
    margin-right: 10px;
}

.star-text {
    float: left;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    color: $black;
}

.star-text p {
    margin: 0;
}

.star-text i {
    color: $blue;
    margin-right: 1%;
}

.photo-box-bar {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.27);
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    box-sizing: border-box;
    padding: 4%;
    position: absolute;
    z-index: 5000;
    text-align: center;
    bottom: 0;
}

.btn-upload {
    color: $white;
    font-size: 19px;
    padding: 13px 15px;
    cursor: pointer;
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border: solid 2px #ffffff;
    flex: 0 0 auto;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background: $blue;
    text-align: left;
    text-transform: uppercase;
    font-weight: 400;
    border: 1px solid $blue;
    transition: 50ms all ease-out;
}

/*.btn-upload:hover {
    background-color:$blue;
    color:$white;
    transition: 50ms all ease-in;
}*/

.btn-upload.browse {
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
    flex: 0 0 auto;
}

.angle-right {
    height: 20px;
    position: absolute;
    right: 9px;
    top: 25%;
    width: 23px;
}

.angle-right i {
    color: #fff;
}

.angle-right img {
    height: 100%;
}

.times {
    position: absolute;
    right: 6px;
    top: 6px;
    width: 26px;
}

.times img {
    height: 100%;
    width: 100%;
}

div#upload-section {
    display: flex;
    flex-flow: column nowrap;
    height: auto;
    flex: 1 0 auto;
    padding: 25px;
}

div#process-upload > div {
    width: 100%;
    max-width: 900px;
    flex-flow: column nowrap;
    position: relative;
}

div.modal {
    display: none;
}

div.modal.active {
    display: flex;
}

@include breakpoint(laptop landscape) {
    .modal-box {
        max-height: 422px;
    }
}

@media only screen and (max-width: 315px) {
    html.ie11 .photo-text {
        margin-top: -37%;
    }
}

@include breakpoint(phone portrait) {
    div#process-upload > div {
        max-width: 360px;
    }

    .modal-headline {
        font-size: 26px;
    }

    html.ie11 .modal-headline {
        transform: none;
    }

    div#upload-main {
        flex-flow: column nowrap;
    }

    .upload-box-left {
        width: 100%;
        margin-bottom: 30px;
        padding-left: 0;
    }

    .upload-box-right {
        width: 100%;
        margin: 0;
        padding-top: 0;
    }

    .upload-left-head {
        font-size: 23px;
        margin-bottom: 10px;
    }

    .upload-button-box {
        margin-top: 10px;
    }

    .photo-box-bar {
        font-size: 15px;
    }

    html.ie11 .photo-box-bar {
        position: relative;
    }

    .modal-box {
        max-height: 250px;
    }

    .btnim-purple {
        font-size: 2vmin !important;
    }

    .crop-box h2 {
        font-size: 18px !important;
    }

    .btnim {
        font-size: 12px !important;
    }

    .star-text {
        font-size: 12px;
    }

    .btn-upload.browse {
        width: 100%;
    }

    html.ie11 .modal-box, html.ie11 .btn-upload.browse {
        transform: none;
    }

    html.ie11 .btn-upload.browse {
        transform: none;
    }

    /*html.ie11 .times {
        top: -29px;
        right: -18px;
    }*/

    div#upload-link div p {
        font-size: 12px;
    }

    div#upload-link div h4 {
        font-size: 14px;
        margin-bottom: 5px;
    }


}

@include breakpoint(phone landscape) {
    div#process-upload > div {
        width: 600px;
    }

    div#close-upload {
        margin-bottom: 20px;
    }

    .upload-box-left {
        width: 51%;
    }

    .upload-box-right {
        width: 50%;
    }

    .upload-left-head {
        font-size: 24px;
    }

    .btn-upload {
        padding: 10px;
    }

    .star-text {
        font-size: 12px;
    }

    .upload-left-text p {
        padding-top: 8%;
    }

    .photo-box-bar {
        font-size: 15px;
    }

    .modal {
        top: 0px;
    }

    .btn-upload.browse {
        width: 100%;
    }

    .modal-box {
        max-height: 250px;
    }

    html.ie11 .modal-box, html.ie11 .btn-upload.browse {
        transform: none;
    }

    html.ie11 .btn-upload.browse {
        transform: none;
    }
}

@include breakpoint(tablet landscape) {
    .modal {
        top: 30px;
    }

    .upload-left-head {
        font-size: 24px;
        line-height: 30px;
    }

    div#process-upload > div {
        width: 700px;
    }

    .modal-box {
        max-height: 450px;
    }

    /*html.ie11 .modal-box {
        transform: translate(5%, 2%);
    }*/

    .btn-upload.browse {
        width: 100%;
    }
}

@include breakpoint(tablet portrait) {
    div#process-upload > div {
        width: 730px;
    }

    .upload-box-left {
        width: 40%;
    }

    .upload-box-right {
        width: 55%;
    }

    .upload-left-head {
        font-size: 20px;
        line-height: 26px;
        margin-top: 010px;
    }

    .btn-upload.browse {
        width: 100%;
    }

    .upload-left-text p {
        margin-bottom: 0;
    }
}

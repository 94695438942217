@import '../framework/breakpoint';

/* Clearfix: contain floats */

.clearfix:before, .clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

/* Hack to fix ie adding extra spacing around scaled svgs */

img[src$=".svg"] {
    width: 100%;
}

/* Portrait iphone 6 and above */

@media only screen and (min-device-width: 375px) and (max-device-width: 1024px) and (orientation: portrait) {
    body.non-mobile #rotate-overlay {
        display: block;
    }
}

body.mobile #mobile-overlay {
    display: block;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

/*----------------------------------------------------------------
Ghost Center
-----------------------------------------------------------------*/

.ghost-center {
    text-align: center;
    white-space: nowrap;
}

.ghost-center:before {
    content: '';
    width: 0;
    height: 100%;
    margin-left: -0.2em;
    /* Adjust for spacing between inline-block elements. */
    display: inline-block;
    vertical-align: middle;
}

.ghost-center > div {
    text-align: left;
    white-space: normal;
    display: inline-block;
    vertical-align: middle;
}

@include breakpoint(phone portrait) {

    .ghost-center > div {
        text-align: left;
        white-space: normal;
        display: inline-block;
        vertical-align: top;
        margin-top:25px;
    }
}

﻿/* ----------------------------------------------------------------
Scenes
-----------------------------------------------------------------*/

@import '../framework/breakpoint';

section.scenes {
    /*display: none;*/
    width: 100%;
    height: auto;
    display: flex !important;
    flex-flow: column nowrap;
    flex: 1 1 auto;
}


div.scene-wrapper {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0 auto;
    overflow-y: hidden;
    overflow-x: hidden;
    flex: 1 1 auto;
    order: 2;
}

#roomscene-data {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

#roomscene-data li {
    margin: 5px;
    flex: 0 0 23.2%;
    padding: 1px;
    font-size: 2vmin;
    position: relative;
    /*&:nth-child(4n+4) {
        margin-right: 0;
    }

    &:nth-child(9) {
        margin-bottom: 0;
    }

    &:nth-child(10) {
        margin-bottom: 0;
    }

    &:nth-child(11) {
        margin-bottom: 0;
    }*/
    &:hover {
        .viz-overlay {
            display: flex;
        }
    }
}

.viz-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*display: flex;*/
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    display: none;

    p {
        font-size: 16px;
        color: #ffffff;
    }
}


#roomscene-data img {
    width: 100%;
    height: 100%;
    /*background-image: url(/img/balls.svg);
    background-repeat: no-repeat;
    background-size: contain;*/
    background-position: center;
    cursor: pointer;
}

#roomscene-data img[src] {
    /*width: 100%;*/
    /*height: auto;*/
    background-image: none;
}

.scenes-header {
    background-color: #ededed;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;
    padding: 16px 0;
    flex: 0 0 auto;



    img {
        width: 100%;
        max-width: 68px;
        margin-right: 20px;
    }

    h1 {
        font-weight: 700;
        flex: 1 1 auto;
        text-align: center;
        letter-spacing: 1px;
        line-height: 1;
    }



    div#landing-projects {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate(0, -50%);

        p {
            font-size: 16px;
            letter-spacing: 1px;
        }
    }
}

.logo-container {
    display: flex;
    flex-flow: row nowrap;
    width: auto;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    min-width: 24%;
}

.scenes-body {
    display: flex;
    flex-flow: column nowrap;
    width: 61%;
    margin: 0 auto;
    flex: 1 0 auto;
}

.viz-description {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    flex: 0 0 auto;
    margin: 30px 0;

    h3 {
        color: #ED1B24;
        font-size: 20px;
        font-weight: 600;
    }
}

div#upload-link {
    cursor: pointer;
    flex: 0 0 auto;
    display: flex;
    flex-flow: row nowrap;
    text-decoration: none;
    color: #434343;
    border: solid 1px #797979;
    padding: 20px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    max-width:96.5%;

    &:hover {
        img.rotate-center {
            -webkit-animation: rotate-center 0.6s ease-in-out both;
            animation: rotate-center 0.6s ease-in-out both;
        }
    }

    img {
        width: 77px;
        flex: 0 0 auto;
        margin-right: 10px;
    }

    div {
        /*flex: 0 0 35%;*/
        flex: 0 0 49%;

        h4 {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 10px;
            color: #ED1B24;
        }

        p {
            font-size: 16px;
            line-height: 1;
        }
    }
}


/* ----------------------------------------------
 * Generated by Animista on 2018-2-5 10:11:51
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@include breakpoint(phone) {
}

@include breakpoint(phone portrait) {


    .viz-description h3 {
        font-size: 16px;
    }

    .scenes-header {
        flex-flow: column nowrap;

        div#landing-projects {
            position: relative !important;
            align-self: flex-end;
            margin-top: 10px;
            transform: none;
            top: auto;
            right: auto;
            margin-right: 10px;
        }
    }

    div#upload-link {
        order: -1;
        margin: 0 0 10px 0;
        padding: 10px;
        /*flex-flow: column nowrap;*/
        img {
            /*margin: 0 0 10px 0;*/
        }

        div {
            flex: 0 0 71%;

            h4, p {
                text-align: left;
                margin-right: 10px;
            }
        }
    }

    .scene-wrapper {
        overflow: visible;
    }

    #roomscene-data li {
        margin: 8px !important;
        flex: 0 0 45%;

        &:nth-child(9) {
            margin-bottom: 0;
        }

        &:nth-child(10) {
            margin-bottom: 0;
        }

        &:nth-child(11) {
            margin-bottom: 0;
        }
    }

    .viz-description {
        margin: 15px 0;
    }

    .scenes-header h1 {
        font-size: 18px;
    }
}

@include breakpoint(phone landscape) {

    div#upload-link {
        padding: 15px;
    }

    div#upload-link div {
        flex: 0 0 85%;
    }

    #roomscene-data li {
        margin: 0 20px 20px 0;
        flex: 0 0 30%;

        &:nth-child(4n+4) {
            margin-right: 20px;
        }
        /*&:nth-child(3n+3) {
            margin-right: 0 !important;
        }*/
        &:nth-child(9) {
            margin-bottom: 20px;
        }
        /*&:nth-child(10) {
            margin-bottom: 0;
        }*/
        &:nth-child(11) {
            margin-bottom: 0;
        }
    }

    div#upload-link {
        order: -1;
        margin-top: 0;
        margin-bottom: 15px;
    }

    .viz-description {
        margin: 15px 0;
    }

    .scenes-header h1 {
        font-size: 24px;
    }
}

@include breakpoint(phone, tablet) {
    .scenes-body {
        width: 100%;
    }
}

@include breakpoint(tablet) {
}

@include breakpoint(tablet portrait) {


    #roomscene-data li {
        flex: 0 0 23.5%;
    }

    div#upload-link div {
        flex: 0 0 57%;
    }

    .scene-wrapper {
        overflow: visible;
    }

    #roomscene-data li {
        margin: 0 20px 20px 0;
        flex: 0 0 31.2%;

        &:nth-child(4n+4) {
            margin-right: 20px;
        }

        &:nth-child(3n+3) {
            margin-right: 0 !important;
        }

        &:nth-child(9) {
            margin-bottom: 20px;
        }

        &:nth-child(10) {
            margin-bottom: 0;
        }

        &:nth-child(11) {
            margin-bottom: 0;
        }
    }

    .scenes-header h1 {
        font-size: 22px;
    }
}

@include breakpoint(tablet landscape) {
    div#upload-link div {
        flex: 0 0 42%;
    }

    .scenes-header h1 {
        font-size: 26px;
    }
}

/* ==========================================================================
    GLOBAL
   ========================================================================== */

body, html, #MAIN {
    width: 100%;
    height: 100% !important;
}

#MAIN {
    display: flex;
    flex-flow: column nowrap;
    padding: 10px 20px;
    min-height: 0;
}

html {
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    min-height: 750px;
}


h1, h2, h3, h4, h5, h6, p {
    padding: 0;
    margin: 0;
    font-weight: normal;
}

h1 {
    padding: 0;
    font-size: 36px;
}

h2 {
    font-size: 3.0vh;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

header {
    display: flex;
    height: auto;
    width: 100%;
    flex: 0 0 auto;
    margin-bottom: 20px;
}

main {
    height: auto;
    flex: 1 1 auto;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    min-height: 0;
}

main section {
    width: 100%;
    height: 100%;
    /*position: absolute;
    top: 0;
    left: 0;*/
}

/* ----------------------------------------------------------------
Loading Graphic
-----------------------------------------------------------------*/

.loading-msg {
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  text-align: center;
  transform: translate(-50%, -50%);
}

.loading-msg img {
  width: auto;
}

@keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0;
  }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1;
  }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0;
  }
}

.uil-ripple-css {
  background: none;
  position: relative;
  width: 200px;
  height: 200px;
}

.uil-ripple-css div {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  width: 0;
  height: 0;
  opacity: 0;
  border-radius: 50%;
  border-width: 12px;
  border-style: solid;
  animation: uil-ripple 2s ease-out infinite;
}

.uil-ripple-css div:nth-of-type(1) {
  border-color: #EC2129;
}

.uil-ripple-css div:nth-of-type(2) {
  border-color: #EC2129;
  animation-delay: 1s;
}

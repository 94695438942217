/* ----------------------------------------------------------------
Landing
-----------------------------------------------------------------*/

.landing {
  display: none;
}

header nav.landing {
  padding: 0 2.9% 0 0;
  font-size: 2.5vh;
}

#landing-projects {
  cursor: pointer;
}

section.landing {
  width: 100%;
  height: 100%;
}

section.landing div.upload {
  width: 97%;
  margin: 2% auto 0 auto;
  position: relative;
  cursor: pointer;
}

section.landing div.callout {
  cursor: pointer;
}

#roomscene-data {
  padding: 0;
  width: 100%;
  margin: 0 auto;
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  overflow: hidden;
}

/*#roomscene-data li {
  width: 25%;
  margin: 0;
  padding: 0;
}

#roomscene-data img {
  width: 100%;
  height: 25vw;
  background-image: url(/img/balls.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;
}
#roomscene-data img[src] {
  width: 100%;
  height: auto;
  background-image: none;
}*/

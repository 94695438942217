/*----------------------------------------------------------------
Lightbox
-----------------------------------------------------------------*/

.lightbox {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    z-index: 99999;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

#calculator-modal.lightbox{
  position: fixed;
}

.lightbox > div {
    width: 100%;
    max-width: 800px;
    background-color: white;
}

.lightbox button:focus {
    outline: none;
}

.lightbox .header, #my-projects .TitleBar {
    padding: 10px 15px;
    margin: 10px;
    color: #434343;
    background-color: #ededed;
    position: relative;
}

.lightbox .header h2, #my-projects .TitleBar h2 {
    padding: 0;
    color: #434343;
}

.lightbox .header .close {
    padding: 0;
    background-color: transparent;
    border: none;
    font-weight: bold;
    position: absolute;
    top: 50%;
    right: 1.5vmin;
    transform: translateY(-50%);
}

.lightbox .content, #my-projects .Content {
    padding: 0 20px 20px;
}

.lightbox .content p {
    margin: 0 0 2vmin;
}

.lightbox .content label, #my-projects .Content label {
    color: #73706d;
    display: block;
}

.lightbox .content label ~ label, #my-projects .Content .lbl ~ .lbl {
    margin-top: 0.8vmin;
}

.lightbox .content input, .lightbox .content textarea, #my-projects .Content input {
    padding: 0.5vmin 0.6vmin;
    border: 1px solid #c2c1c1;
    display: block;
}

.lightbox .content textarea {
    width: 100%;
    min-height: 100px;
}

.lightbox .content .buttons {
    margin-top: 2vmin;
}

.lightbox .content button, #my-projects .loginBTN, #my-projects .forgotBTN, #my-projects .regBTN, #my-projects .changePwdBTN, #my-projects .saveBTN, #my-projects .Use, #my-projects .Remove, #myProjectBTNS .Edit {
    padding: 10px;
    color: white;
    text-transform: uppercase;
    background-color: #ed1b24;
    border: none;
    display: inline-block;
    letter-spacing: 1px;
    cursor: pointer;
}

.lightbox .validation-error, #my-projects .errors {
    margin-top: 0.3vmin;
    color: #ed1b24;
    font-size: 0.9em;
    letter-spacing: 1px;
}

#email-modal .required-notice {
    margin: 1vmin 0;
    font-size: 0.8em;
    text-align: right;
}

#email-modal input[type="text"], #my-projects .Content input[type="text"], #my-projects .Content input[type="password"] {
    width: 100%;
    max-width: 250px;
}

#my-projects .Remove {
    background-color: #434343;
}
